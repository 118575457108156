import { setWithExpiry, getWithExpiry } from '../utils/localStorage'
import moment from 'moment'
import { getGlobals } from '../utils/django'
import { alertSuccessConfirmation } from './alerts'
import { show } from '../utils/helpers/show'
import { onclickElements } from '../utils/helpers/eventHelpers'

/* eslint no-console: "off" */
const _log = console.log
const _warn = console.warn
const _error = console.error
let timeout
const maxLogTime = 300000 // 5 minutes

/**
 * Create a log that is ignored by default but added to log file if debug mode is active
 *
 * @param {Array<string>} message - list of messages to print
 */
export function log(...message) {
	if (getWithExpiry('debugMode') === true) {
		addLine('log', ...message)
	}
}

/**
 *
 * Create a warning that is ignored by default but added to log file if debug mode is active
 *
 * @param {Array<string>} message - list of warnings to print
 */
export function warn(...message) {
	if (getWithExpiry('debugMode') === true) {
		addLine('wrn', ...message)
	}
}

/**
 *
 * Create a warning that is ignored by default but added to log file if debug mode is active
 *
 * @param {Array<string>} message - list of errors to print
 */
export function error(...message) {
	if (getWithExpiry('debugMode') === true) {
		addLine('err', ...message)
	}
}

/**
 *
 * If the logger is set to be active start tacking
 *
 * @param {boolean} notifyUser - if true shows a sweety alert that informs the user of the recording
 */
export function startLogger(notifyUser) {
	if (getWithExpiry('debugMode')) {
		show('#supportDataDiv')
		onclickElements({
			'#stopDebugger': () => downloadLog(),
		})
		window.clearTimeout(timeout)
		timeout = window.setTimeout(hideLogger, maxLogTime)
		console.log = (...args) => {
			log(...args)
			_log(...args)
		}
		console.warn = (...args) => {
			warn(...args)
			_warn(...args)
		}
		console.error = (...args) => {
			error(...args)
			_error(...args)
		}
		window.addEventListener('rejectionhandled', (event) => {
			console.error(event.reason)
		})
		window.addEventListener('unhandledrejection', (event) => {
			console.error(event.reason)
		})
		window.addEventListener('error', (event) => {
			console.error(event.message)
		})
		document.querySelectorAll('input').forEach((el) => {
			el.addEventListener('change', (event) => {
				log(
					'Changed ' +
						(event.target.name || event.target.id || event.target.classList) +
						' to ' +
						event.target.value
				)
			})
		})
		document.querySelectorAll('textarea').forEach((el) => {
			el.addEventListener('change', (event) => {
				log(
					'Changed ' +
						(event.target.name || event.target.id || event.target.classList) +
						' to ' +
						event.target.value
				)
			})
		})
		document.querySelectorAll('select').forEach((el) => {
			el.addEventListener('change', (event) => {
				log(
					'Changed ' +
						(event.target.name || event.target.id || event.target.classList) +
						' to ' +
						event.target.value
				)
			})
		})
		document.querySelectorAll('a').forEach((el) => {
			el.addEventListener('click', (event) => {
				log(
					'Clicked ' +
						(event.target.name ||
							event.target.id ||
							event.target.classList ||
							event.target.href)
				)
			})
		})
		document.querySelectorAll('btn').forEach((el) => {
			el.addEventListener('click', (event) => {
				log(
					'Clicked ' +
						(event.target.name ||
							event.target.id ||
							event.target.classList ||
							event.target.href)
				)
			})
		})
		if (notifyUser) {
			alertSuccessConfirmation('CIx000xLoggerStarted')
		}
	}
}

/**
 * Enables the logger
 */
export function enableLogger() {
	setWithExpiry('debugMode', true, maxLogTime)
	startLogger(true)
}

/**
 * Disables the logger
 */
export function hideLogger() {
	if (!getWithExpiry('debugMode')) {
		localStorage.removeItem('debugMode')
		localStorage.removeItem('debugLog')
		document.querySelector('#supportDataDiv')?.classList.add('hidden')
	}
}

/**
 *	Initialize download of log files and disable logging
 */
export function downloadLog() {
	let errorLog = `${navigator.userAgent}\n`
	errorLog += `User ID: ${getGlobals().userID}\n`
	errorLog += `Language: ${getGlobals().languageCode}\n`
	errorLog += '---------------------------------\n'
	const debugLog = getWithExpiry('debugLog')
	if (debugLog) {
		debugLog.forEach((el) => {
			errorLog += `[${el.date}][${el.kind}]: ${el.msg}\n`
		})
		errorLog = errorLog.replace(/\n\n/g, '\n')
	}

	const blob = new Blob([errorLog], { type: 'text/json' })
	const a = document.createElement('a')
	const e = document.createEvent('MouseEvents')
	a.download = 'easyverein.log'
	a.href = window.URL.createObjectURL(blob)
	a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
	e.initMouseEvent(
		'click',
		true,
		false,
		window,
		0,
		0,
		0,
		0,
		0,
		false,
		false,
		false,
		false,
		0,
		null
	)
	a.dispatchEvent(e)

	localStorage.removeItem('debugMode')
	hideLogger()
}

/**
 * Adds a line to the logger
 *
 * @param {string} level - logging level
 * @param {Array<string>} log - message(s) to log
 */
function addLine(level, ...log) {
	let string = ''

	if (Array.isArray(log)) {
		log.forEach((el) => {
			string += el + '\n'
		})
	} else {
		string += JSON.stringify(log)
	}

	const msg = { date: moment(), msg: string, kind: level }

	let recentLog = getWithExpiry('debugLog')
	if (recentLog) {
		recentLog.push(msg)
	} else {
		recentLog = [msg]
	}
	setWithExpiry('debugLog', recentLog, maxLogTime)
	window.clearTimeout(timeout)
	timeout = window.setTimeout(hideLogger, maxLogTime)
}
