import Inputmask from 'inputmask'
import { Datepicker } from 'vanillajs-datepicker'
import { getGlobals } from '../django'
import de from 'vanillajs-datepicker/locales/de'
import es from 'vanillajs-datepicker/locales/es'
import moment from 'moment'
import { alertWarning, alertInformation } from '../../globals/alerts'

/**
 * Thin wrapper for the default datepicker
 *
 * @param {string} selector - CSS selector
 * @param {string} container - CSS selector of the element that the picker is attached to (default: body)
 * @param {string} buttonClass - CSS class for <button> elements (default: btn)
 * @param {string} dateFormat - The format of the date (should be dd.mm.yyyy to work properly)
 * @param {string} placeholder - The placeholder string
 * @param {number} plausibleMinYear - The minimum year that is considered to be acceptable without informing the user that something should be checked
 * @param {number} plausibleMaxYear - The maximum year that is considered to be acceptable without informing the user that something should be checked
 * @param {number} centuryThreshold - The threshold used to determine for how many years into the future from now on the current century will be assumed for 2-digit years
 * @returns {Array<object>} list of datepicker instances
 */
export function createDefaultDatepicker(
	selector,
	container = 'body',
	buttonClass = 'btn',
	dateFormat = 'dd.mm.yyyy',
	placeholder = '__.__.____',
	plausibleMinYear = 1900,
	plausibleMaxYear = 2099,
	centuryThreshold = 10
) {
	const languageCode = getGlobals().languageCode
	const pickers = []
	const options = {
		buttonClass: buttonClass,
		container: container,
		language: languageCode,
		autohide: true,
		updateOnBlur: true,
		format: {
			toValue(date, format, locale) {
				return formatYear(date, dateFormat).dateValue
			},
			toDisplay(date, format, locale) {
				return `${date.toLocaleDateString('de-DE', {
					month: '2-digit',
					day: '2-digit',
				})}${String(date.getFullYear()).padStart(4, '0')}`
			},
		},
	}

	// dd.mm.yyyy is the type of the mask, so do not change it
	const inputMask = new Inputmask('dd.mm.yyyy', {
		inputFormat: dateFormat,
		placeholder: placeholder,
		shiftPositions: false,
		// These years are the minimum and maximum that can be entered
		// The check for plausible dates happens elsewhere
		yearrange: { minyear: 100, maxyear: 9999 },
		onBeforePaste: function (pastedValue, opts) {
			pastedValue = pastedValue.replace(/[-/]/g, '.')
			const formattedDateData = formatYear(pastedValue, dateFormat, true)
			return formattedDateData.dateValue.format('DD.MM.YYYY')
		},
	})

	inputMask.mask(selector)
	Object.assign(Datepicker.locales, de, es)

	/**
	 * Format a date given as a string (potentially incomplete containing underscores), format it and return the date as a moment and some data about its validity
	 * The year will be completed if it has 2 digits
	 * The year will be replaced by the current year if has only one digit
	 * If the date is invalid, the current date will be returned
	 *
	 * @param {string} value - The date to format as a string
	 * @param {string} dateFormat - The date format
	 * @param {boolean} onBlur - Flag indicating that the function was called in an onBlur event listener (as opposed to the datepicker's toValue function)
	 * @returns {object} - An object containing the date as a moment, a flag indicating that the year was corrected to the current year and a flag indicating whether the date is valid
	 */
	function formatYear(value, dateFormat, onBlur = false) {
		let newValue = ''

		const dateData = value.match(/([0-9_]{2}\.[0-9_]{2}\.)([0-9]{0,4})/)
		if (!dateData) {
			if (value) {
				alertWarning('DIx000xInvalidDate', undefined, {
					shortDescriptionOnly: true,
					messageData: value,
				})
			}
			return {
				dateValue: moment(),
				yearCorrectedToCurrentYear: undefined,
				isValid: false,
			}
		}
		/* eslint no-unused-vars: "off" */
		const [_all, rest, yearData] = dateData
		const yearAsNumber = yearData ? parseInt(yearData) : 0
		if (!rest || isNaN(yearAsNumber)) {
			return {
				dateValue: moment(),
				yearCorrectedToCurrentYear: undefined,
				isValid: false,
			}
		}

		// Handle year input values with 2 or fewer positions
		let year = yearAsNumber
		let yearCorrectedToCurrentYear = false
		if (yearAsNumber < 100) {
			const currentYear = new Date().getFullYear()
			// One digit years are considered valid if they have a leading 0 in which case it's assumed that it's a 2000s year
			// Otherwise it's considered invalid and changed to the current year because single-digit years don't make much sense
			if (yearAsNumber < 10) {
				if (yearData.startsWith('0') && yearData.length === 2) {
					year = 2000 + yearAsNumber
				} else {
					year = moment().year()
					yearCorrectedToCurrentYear = true
				}
			}
			// If the year has 2 digits and is less than "centuryThreshold" years in the future relative to the current year,
			// it's assumed to be a 2000s year
			// Otherwise it's assumed that it's a 1900s year
			// This was a HSV wish
			else if (yearAsNumber <= (currentYear % 100) + centuryThreshold) {
				year = 2000 + yearAsNumber
			} else {
				year = 1900 + yearAsNumber
			}
		}

		newValue = `${rest}${year}`

		let dateValue = moment(newValue, dateFormat.toUpperCase())
		const isValid = dateValue.isValid()
		if (!isValid) {
			dateValue = moment()
		}
		// If the date is invalid or implausible, show corresponding alerts but only when blurring
		// because otherwise there'd be alerts when the page is loaded
		if (onBlur) {
			if (!isValid) {
				alertWarning('DIx000xInvalidDate', undefined, {
					shortDescriptionOnly: true,
					messageData: value,
				})
			} else if (yearCorrectedToCurrentYear) {
				alertInformation('DIx000xYearGotChangedToCurrentYear', undefined, {
					shortDescriptionOnly: true,
				})
			} else if (year < plausibleMinYear || plausibleMaxYear < year) {
				alertInformation('DIx000xDateOutsidePlausibleRange', undefined, {
					shortDescriptionOnly: true,
					messageData: `${plausibleMinYear} - ${plausibleMaxYear}`,
				})
			}
		}
		return { dateValue, yearCorrectedToCurrentYear, isValid }
	}

	/**
	 * When Enter is pressed, the input field gets blurred
	 *
	 * @param {object} event - The keydown event
	 */
	function keyDownListener(event) {
		const currentTarget = event.currentTarget
		currentTarget.datepicker?.hide()
		if (event.key === 'Enter') {
			event.preventDefault()
			if (!currentTarget.value && currentTarget.dataValue === '') {
				currentTarget.value = moment().format('DD.MM.YYYY')
			}
			// This needs to be done like this without dispatchEvent because otherwise the actual blurring might happen twice in case an alert is shown
			currentTarget.blur()
		}
	}

	/**
	 * Format the year and set the flag to show alerts to true
	 *
	 * @param {object} event - The blur event
	 */
	function blurListener(event) {
		formatYear(event.currentTarget.value, dateFormat, true)
		event.currentTarget.dataValue = event.currentTarget.value
	}

	document.querySelectorAll(selector).forEach((element) => {
		element.addEventListener('keydown', keyDownListener)
		// This needs to be done after defining the event listener for the keydown event
		pickers.push(new Datepicker(element, options))
		element.addEventListener('blur', blurListener)
		element.placeholder = placeholder
		element.dataValue = element.value
		element.setAttribute('autocomplete', 'off') // Disable autocomplete because it could overlap with the datepicker
	})

	return pickers
}
