import { Tooltip } from 'bootstrap'
import { getWithExpiry, setWithExpiry } from '../utils/localStorage'
import { initErrorMessageEventListeners } from '../globals/messages'
import { getContextData } from '../utils/django'
import Colors from '../design/colors'
import { gettext, interpolate } from '../utils/translation'

/**
 * If this is included on every warning dismiss an cookie is saved that hides this warning for the given amount of time
 */
export default function startWarnings() {
	document.querySelectorAll('.alert').forEach((el) => {
		const key = el.id
		if (!getWithExpiry(key) && key.startsWith('announcement')) {
			el.classList.remove('hidden') // remove 'hide' class if cookie exists
		}
	})

	document.querySelectorAll('.alert .btn-close').forEach((el) => {
		el.addEventListener('click', (e) => {
			const key = e.currentTarget.parentElement.id
			if (key) {
				const ttl = parseInt(e.currentTarget.dataset.dismiss) * 1000
				setWithExpiry(key, 'dismissed', ttl)
			}
			el.querySelectorAll('.tooltip')?.forEach((tooltipElement) => {
				const tooltip = Tooltip.getOrCreateInstance(tooltipElement)
				tooltip?.hide()
				tooltip?.dispose()
			})
		})
	})

	initErrorMessageEventListeners() // this is needed for warnings which include extendedErrorData from backend (messagingTools.py)
}

/**
 * Printing the warning duck in the console that instruct users to not copy paste/enter code into the console they don´t understand
 */
export function warningDuck() {
	if (!getContextData('is_debug')) {
		console.clear()
	}

	const colors = [
		`color: ${Colors.Success};`,
		'color: inherit;',
		`color: ${Colors.Success};`,
		'color: inherit;',
	]
	for (let i = 0; i < 17; i++) {
		colors.push('background: #00000000;', `background: ${Colors.Success};`)
	}

	const message = `
	${interpolate(gettext('Quak, hallo %(username)s, quak!'), {
		username: getContextData('userName').length
			? getContextData('userName')
			: gettext('du unbekannter'),
	})}
	${gettext(
		'Quak, falls dich jemand bittet hier etwas einzugeben was du nicht verstehst handelt es sich womöglich um einen Betrug!'
	)}
	${gettext(
		'Gebe am besten hier keine Sachen ein wenn du nicht genau weißt was du machst!'
	)}
	`

	let bubble = ''
	let wallX = '' // _
	for (const row of message.split('\n')) {
		if (!row || (row.match(/ /g) || []).length === row.length || row === '	')
			continue
		if (row.length > wallX.length) {
			wallX = '_'.repeat(row.length)
		}
		bubble += `|> ${row}\n`
	}
	wallX += '___'
	bubble = `${bubble}${wallX}`.replaceAll('	', '')

	/* eslint no-console: "off" */
	console.log(
		`
        %c
                                           o
  ,---.,---.,---.,   .|    |,---.,---.,---..,---.
  |---',---|\`---.|   | \\  / |---'|    |---'||   |
  \`---'\`---^\`---'\`---|  \`'  \`---'\`    \`---'\`\`   '
                 \`---'    %c\n
${bubble}
%c        %c\\
%c         \\        %c°°°°°°
%c          \\      %c°°     °°
%c           \\    %c°  (•)    °°
%c              %c°°          °
%c                 %c°°     °
%c                %c°    °
%c               %c°    °  °°°%c  %c°°%c      %c°
%c             %c°°     °     °°  °°%c  %c°°°
%c             %c°      °           °°  °
%c             %c°°       °      °°     °
%c             %c°°        °°°°°°      °
%c              %c°°°                °
%c                %c°°°°          °°
%c                  %c°°°°°°°°°°°°%c\n\n
	`,
		...colors
	)
}
