import { triggerExtendedErrorMessage } from './messages'
import { enableLogger } from '../globals/logger'

/**
 * setup shortCuts on all Pages in easyVerein
 */
export default function startShortCuts() {
	const shortCuts = {
		'Control + Shift + S': 'show all ShortCuts in console',
		'Control + e': 'open all extended ErrorMessages on currrent side',
		'Control + Shift + E': 'start recording debug outputs',
	}
	const isKeyPressed = {}

	document.addEventListener('keydown', function (keyDownEvent) {
		const key = keyDownEvent.key
		if (key === undefined) {
			return
		}
		isKeyPressed[key] = true
		if (key.length === 1 && key.match(/[a-z]/i)) {
			const capitalKey = key.toUpperCase()
			const lowercaseKey = key.toLowerCase()
			isKeyPressed[capitalKey] = true
			isKeyPressed[lowercaseKey] = true
		}
		/**
		 * Check if the keyList is a subset of the keys that are currently pressed
		 *
		 * @param {Array} keyList - List of keys that should be pressed
		 * @returns {boolean} - If the keyList is a subset of the keys that are currently pressed
		 */
		function shortcutTrigger(keyList) {
			return (
				keyList.every((element) => isKeyPressed[element]) &&
				keyList.includes(key)
			)
		}
		switch (true) {
			// Make sure to use the more specific case first, because the more general case would also match
			// There was a problem with lowercase and capital letters in the dictionary, so we have to check both
			// If you pressed Shift + Letter, it would add the capital letter to the dictionary, but not the lowercase letter
			// If you then released Shift first and then the letter, it would not remove the capital letter from the dictionary
			// You could then acces the keybind without the letter being pressed
			// Thats why we need to check for Ctrl + Shift + E before Ctrl + e
			case shortcutTrigger(['Control', 'Shift', 'E']):
				// Start support logging
				enableLogger()
				break
			case shortcutTrigger(['Control', 'e']):
				// Control + e open all ExtendedErrorMessages on current Side
				document.querySelectorAll('.err-ex-call').forEach((err) => {
					triggerExtendedErrorMessage(err)
				})
				break
			case shortcutTrigger(['Control', 'Shift', 'S']):
				// Show shortCuts in Console
				/* eslint no-console: "off" */
				console.log(shortCuts)
				break
			default:
				break
		}

		if (
			keyDownEvent.altKey &&
			['ArrowDown', 'ArrowUp'].includes(keyDownEvent.key)
		) {
			const navbar = document.getElementById('navbar')
			const navigationElement = navbar.querySelector('.active')
			const treeElement = navbar.querySelector('.treeview .active')
			const element = treeElement || navigationElement
			const currentLocation = element.querySelector('a')

			if (currentLocation) {
				const allLocations = navbar.querySelectorAll('a')
				let nextLocation = null
				let i = 0

				for (const location of allLocations) {
					if (currentLocation === location) {
						nextLocation =
							allLocations[keyDownEvent.key === 'ArrowDown' ? i + 1 : i - 1]
						if (
							nextLocation.href.includes('/public/') ||
							nextLocation.href.includes('/#')
						) {
							nextLocation =
								allLocations[event.key === 'ArrowDown' ? i + 2 : i - 2]
						}
						break
					}
					i += 1
				}
				if (nextLocation) {
					nextLocation.click()
				}
			}
		}
	})

	document.addEventListener('keyup', function (keyUpEvent) {
		const key = keyUpEvent.key
		if (key === undefined) {
			return
		}
		isKeyPressed[key] = false
		if (key.length === 1 && key.match(/[a-z]/i)) {
			const capitalKey = key.toUpperCase()
			const lowercaseKey = key.toLowerCase()
			isKeyPressed[capitalKey] = false
			isKeyPressed[lowercaseKey] = false
		}
	})
}
