import { getGlobals, getContextData } from './django'
import { setVisibility } from '../utils/helpers/show.js'
import { headers } from './helpers/apiHelpers'
import { getCookie, setCookie } from './cookies'
import { Dropdown } from 'bootstrap'
import { setLoadingCircle } from './loader.mjs'

/**
 * Sets up the chat and knowledge base
 *
 * @param {boolean} isLitePage - Whether the page is a lite page or not
 */
export function initializeFront(isLitePage = false) {
	const button = document.querySelector('#openSupportChat')
	button?.addEventListener('click', async (e) => {
		startLoadingAnimation(button, isLitePage)
		if (button.dataset.initialized !== 'true') {
			await initFrontChat(button, true)
		} else {
			await showFrontChat()
		}
		setHasMessages(false)
		stopLoadingAnimation(button, isLitePage)
		setCookie('openFrontChat', true)
		closeHelpDropdown()
	})
	const helpDropdown = document.querySelector('#helpAndChatDropdown')
	if (helpDropdown) {
		helpDropdown.parentElement
			.querySelectorAll('a.dropdown-item:not(#openSupportChat)')
			.forEach((e) => {
				e.addEventListener('click', () => {
					closeHelpDropdown()
				})
			})
	}
	if (getCookie('openFrontChat')) {
		initFrontChat(button, false)
	}
}

/**
 * Initializes the page-related chat events
 */
export function initPageRelatedFrontChatEvents() {
	document.querySelectorAll('.chatLink').forEach((element) => {
		element.addEventListener('click', (event) => {
			document.querySelector('#openSupportChat').click()
		})
	})
	document.querySelectorAll('.chatBotLink').forEach((element) => {
		element.addEventListener('click', (event) => {
			setTimeout(() => {
				sendMessageInFrontChat('Starte den Bot', true)
			}, 50)
		})
	})
	document.querySelectorAll('.chatHumanLink').forEach((element) => {
		element.addEventListener('click', (event) => {
			setTimeout(() => {
				sendMessageInFrontChat('Ich möchte mit dem Support sprechen', false)
			}, 50)
		})
	})
}
/**
 * Initializes the chat window
 *
 * @param {HTMLElement} initButton - The button that initializes the chat
 * @param {boolean} show - Whether to also show the chat window or not
 */
async function initFrontChat(initButton, show = false) {
	const globals = getGlobals()
	let chatId = globals.frontChatId
	let frontIdentity = globals.frontIdentity
	if (frontIdentity.length === 0) {
		chatId = globals.frontSalesChatId
		frontIdentity = {}
	}
	frontIdentity.chatId = chatId
	frontIdentity.useDefaultLauncher = false

	window.FrontChat('init', frontIdentity)

	// Front has no real callback for when the chat is ready, so we have to poll
	while (!isFrontChatInitialized()) {
		await sleep(200)
	}
	if (show) {
		await showFrontChat()
	}
	initButton.dataset.initialized = 'true'
	window.FrontChat('onUnreadChange', (data) => {
		if (data.unread_count && !isFrontChatOpen()) {
			setHasMessages(true)
		}
	})
}

/**
 * Opens the chat window
 */
async function showFrontChat() {
	while (!isFrontChatOpen()) {
		window.FrontChat('show')
		await sleep(200)
	}
	const supportUnavailableMessage = getContextData('supportUnavailable')
	const chatFrame = document.getElementById('front-chat-iframe')
	const chatDocument = chatFrame?.contentWindow?.document
	if (
		supportUnavailableMessage &&
		chatDocument &&
		!chatDocument.querySelector('.supportUnavailableMessage')
	) {
		const html = `<div class="supportUnavailableMessage" style="margin: 1rem; padding: .5rem; border: 1px solid #23985D; background-color: #E2F8ED; font-size: smaller; border-radius: 1rem;">${supportUnavailableMessage}</div>`
		chatDocument
			?.querySelector('textarea')
			?.parentNode?.parentNode?.parentNode?.previousElementSibling?.insertAdjacentHTML(
				'beforeend',
				html
			)
	}
}

/**
 * Checks if the chat window is initialized
 *
 * @returns {boolean} Whether the chat window is initialized or not
 */
function isFrontChatInitialized() {
	const chatFrame = document.getElementById('front-chat-iframe')
	return (
		chatFrame && chatFrame.contentWindow && chatFrame.contentWindow.FrontChatApp
	)
}

/**
 * Checks if the chat window is open
 *
 * @returns {boolean} Whether the chat window is open or not
 */
function isFrontChatOpen() {
	const chatFrame = document.getElementById('front-chat-iframe')
	const chatHolder = chatFrame.contentWindow.document.querySelector(
		'#front-chat-holder > div'
	)
	return (
		isFrontChatInitialized() &&
		chatFrame.clientHeight &&
		chatHolder.style.opacity === '1'
	)
}

/**
 * Toggles the visibility of the unread messages display
 * Calls the API to remove the unread status
 *
 * @param {boolean} hasMessages - whether the user has messages or not
 */
function setHasMessages(hasMessages) {
	setVisibility('.has-chats-display', hasMessages)
	setVisibility('.has-no-chats-display', !hasMessages)

	if (!hasMessages && getGlobals().frontIdentity.email !== undefined) {
		fetch('/webhook/front/read/', { method: 'POST', headers: headers() })
	}
}

/**
 * Closes the help dropdown
 */
function closeHelpDropdown() {
	const dropdownElement = document.querySelector('#helpAndChatDropdown')
	if (dropdownElement) {
		Dropdown.getOrCreateInstance(dropdownElement).hide()
	}
}

/**
 * Sleeps for a given amount of time
 *
 * @param {number} ms - The time to sleep in milliseconds
 * @returns {Promise<void>} A promise that resolves after the given time
 */
function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Starts the loading animation for the button
 *
 * @param {HTMLElement} button - the button for the animation
 * @param {boolean} isLitePage - whether the page is a lite page or not
 */
function startLoadingAnimation(button, isLitePage) {
	if (isLitePage) {
		setLoadingCircle(button.querySelector('.fc-button'), false, false, false)
	} else {
		setLoadingCircle(button, false, true)
	}
}

/**
 * Stops the loading animation for the button
 *
 * @param {HTMLElement} button - the button for the animation
 * @param {boolean} isLitePage - whether the page is a lite page or not
 */
function stopLoadingAnimation(button, isLitePage) {
	button.querySelector('.loading')?.remove()
	if (isLitePage) {
		button
			.querySelector('.fc-button')
			.insertAdjacentHTML('beforeend', '<i class="far fa-comment-lines"></i>')
	}
}

/**
 * Sends a message in the FrontChat
 *
 * @param {string} message - The message to send
 * @param {boolean} aiChat - Whether the chat is with the AI or not
 */
async function sendMessageInFrontChat(message, aiChat) {
	if (!isFrontChatInitialized()) {
		console.error('FrontChat is not initialized.')
		return
	}

	const chatFrame = document.getElementById('front-chat-iframe')
	const chatDocument = chatFrame.contentWindow.document
	const textArea = chatDocument.querySelector('textarea')
	let botButton
	let humanButton
	let sendButton
	chatDocument.querySelectorAll('button').forEach((button) => {
		if (button.innerText === 'Ich möchte mit einem KI Bot sprechen') {
			botButton = button
		}
		if (button.innerText === 'Ich möchte mit einem Menschen sprechen') {
			humanButton = button
		}
		if (button.parentNode.dataset.testid === 'message-input-submit-button') {
			sendButton = button
		}
	})

	if (textArea) {
		textArea.value = message
		const event = new Event('input', { bubbles: true })
		textArea.dispatchEvent(event)

		if (sendButton) {
			await sleep(100)
			sendButton.click()
		} else {
			console.error('Send button not found.')
		}
	} else if (aiChat && botButton) {
		botButton.click()
	} else if (!aiChat && humanButton) {
		humanButton.click()
	} else {
		console.error('Text area and buttons not found.')
	}
}
