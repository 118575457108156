/**
 * Show a HTML element based on its selector by removing the .hidden class
 *
 * @param {string} selector - string of the selector; what you'd add in querySelector
 */
export function show(selector) {
	document.querySelectorAll(selector).forEach((element) => {
		showElement(element)
	})
}

/**
 * Hide a HTML element based on its selector by adding the .hidden class
 *
 * @param {string} selector - string of the selector; what you'd add in querySelector
 */
export function hide(selector) {
	document.querySelectorAll(selector).forEach((element) => {
		hideElement(element)
	})
}

/**
 * Toggle visibility of a HTML element based on its selector by toggling the .hidden class
 *
 * @param {string} selector - string of the selector; what you'd add in querySelector
 */
export function toggleVisibility(selector) {
	document.querySelectorAll(selector).forEach((element) => {
		toggleElementVisibility(element)
	})
}

/**
 * Set the visibility of all HTML elements matching selector.
 *
 * @param {string} selector - string of the selector; what you'd add in querySelector
 * @param {boolean} visible - if true shows the element, else hides it
 */
export function setVisibility(selector, visible) {
	document.querySelectorAll(selector).forEach((element) => {
		setElementVisibility(element, visible)
	})
}

/**
 * Show a HTML element by removing the .hidden class
 *
 * @param {object} element - HTML node
 */
export function showElement(element) {
	element.classList.remove('hidden')
}

/**
 * Hide a HTML element by adding the .hidden class
 *
 * @param {object} element - HTML node
 */
export function hideElement(element) {
	element.classList.add('hidden')
}

/**
 * Toggle the visiblity of a HTML element by toggling the .hidden class
 *
 * @param {object} element - HTML element
 */
export function toggleElementVisibility(element) {
	if (element.classList.contains('hidden')) {
		element.classList.remove('hidden')
	} else {
		element.classList.add('hidden')
	}
}

/**
 * Sets the visiblity of the element based on `visible`.
 *
 * @param {HTMLElement} element - HTML element
 * @param {boolean} visible - if true shows the element, else hides it
 */
export function setElementVisibility(element, visible) {
	if (visible) {
		showElement(element)
	} else {
		hideElement(element)
	}
}

/**
 * function to check if a HTML element is visible
 * DONT use the browser "standard" element.checkVisibility() its not standard in safari see #12671
 *
 * @param {HTMLElement} element - the html element
 * @returns {boolean} - visible or not
 */
export function checkVisibility(element) {
	if (!element) return false

	const rect = element.getBoundingClientRect()
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
		element.offsetParent !== null // check if the element is not hidden by a parent
	)
}
