import $ from 'jquery'
import 'corejs-typeahead'
import 'jQuery-QueryBuilder'
import 'jQuery-QueryBuilder/dist/i18n/query-builder.de'
import { getLanguageCode, gettext } from './translation'
import { saveFilter } from './customFilters'
import { isEmptyOrUndefined } from './helpers/apiHelpers'
import { createDefaultDatepicker } from './helpers/datepicker'
import { createAutocompleteLabel } from './input.mjs'
import { Datepicker } from 'vanillajs-datepicker'
import { FILTER_INPUT_TYPE, loadSessionFilter } from './filterTools'

/**
 * - initialize a QueryBuilder instance
 *
 * @param {string} id - id of the queryBuilder element
 * @param {object} settings - queryBuilder settings as json data
 * @param {boolean} sessionFilter - load sessionFilter
 * @param {boolean} selectUsValueSetter - use selectUsValueSetter
 */
export default function createQueryBuilder(
	id,
	settings,
	sessionFilter = false,
	selectUsValueSetter = false
) {
	setQueryBuilderLanguage()

	if (!settings.plugins) {
		settings.plugins = ['ev_date_plugin']
	} else {
		settings.plugins.push('ev_date_plugin')
	}

	$(id).queryBuilder(settings)

	if (selectUsValueSetter) {
		enableSelectUsValueSetter()
	}

	if (sessionFilter) {
		loadSessionFilter()
	}

	// Since any currency filter is throwing an error if the value is written with a comma or currency symbol (and can't reach the backend where it previously was converted to the correct format)
	$(id).on(
		'afterUpdateRuleValue.queryBuilder.filter afterUpdateRuleOperator.queryBuilder.filter',
		(event, rule) => {
			if (rule.filter.type === 'double' && typeof rule.value === 'string') {
				if (rule.value.includes(',')) {
					rule.value = rule.value.replace(',', '.')
				}
				if (rule.value.includes('€')) {
					rule.value = rule.value.replace('€', '')
				}
			}
		}
	)
}

/**
 * Translates queryBuilder UI and add icons
 */
export function setQueryBuilderLanguage() {
	$().queryBuilder.defaults({
		lang_code: getLanguageCode(),
		lang: overwriteQueryBuilderTexts(),
		icons: {
			add_group: 'far fa-plus-circle',
			add_rule: 'far fa-plus',
			remove_group: 'far fa-times-circle',
			remove_rule: 'far fa-times',
			error: 'far fa-exclamation-circle',
		},
	})
}

/**
 * overwrite some translations from QuerBuilder standart texts
 *
 * @returns {object} queryBuilder lang object as json
 */
function overwriteQueryBuilderTexts() {
	return {
		conditions: {
			AND: gettext('Alle (UND)'),
			OR: gettext('Mind. Eins (ODER)'),
		},
		operators: {
			is_not_null: gettext('ist gesetzt'),
			is_null: gettext('ist nicht gesetzt'),
		},
	}
}

/**
 * Definition of a jQuery-Querybuilder plugin that handles dates with the vanilla datepicker
 *
 * @param {object} options - configuration options, see `registerDatepickerPlugin` for defaults
 */
function datepickerPlugin(options) {
	// `this` is the QueryBuilder instance
	const isEVDatePlugin = (rule) =>
		rule.filter.type === 'date' || rule.filter.type === 'datetime'
	this.on('afterCreateRuleInput.queryBuilder', createDatePicker)
	this.on('getRuleValue.queryBuilder.filter', getRuleValue)
	this.on('validateValue.queryBuilder.filter', validateValue)

	/**
	 * Initializes and shows a Datepicker for the given input.
	 * Callback for `afterCreateRuleInput.queryBuilder`
	 *
	 * @param {Event} event - jQuery event, unused
	 * @param {object} rule - `Rule` object from jQuery-Querybuilder
	 */
	function createDatePicker(event, rule) {
		// Changed the behaviour in 14175 because of a datepicker rework. Now we only create a datepicker if there is none.
		// We also do not need to save it in a dictionary anymore. If this leads to problems, we can change it back.
		if (isEVDatePlugin(rule) && getPicker(rule) === undefined) {
			const input = rule.$el.find('input').get(0)
			createDefaultDatepicker(`[name="${input.name}"]`, '#filter')
		}
	}

	/**
	 * Retrieves the value from the datepicker.
	 * This should not be needed after changing the change event in `filterTools.createFilter`, but I keep it for completness.
	 * Callback for `getRuleValue.queryBuilder.filter`.
	 *
	 * @param {Event} event - jQuery event, unused
	 * @param {object} rule - `Rule` object from jQuery-Querybuilder
	 * @returns {string|undefined} date in `options.format` or empty string
	 */
	function getRuleValue(event, rule) {
		if (isEVDatePlugin(rule)) {
			const picker = getPicker(rule)
			return picker.getDate(options.format) || ''
		}
	}

	/**
	 * Checks if the value of the picker is an empty string
	 * Callback for `validateValue.queryBuilder.filter`.
	 *
	 * @param {Event} event - jQuery event, unused
	 * @param {any} unkown - I only every got `undefined` here, from the docs it should be a value
	 * @param {object} rule - `Rule` object from jQuery-Querybuilder
	 * @returns {boolean|Array<string>|undefined} `true` if value is valid or an array of error codes
	 */
	function validateValue(event, unkown, rule) {
		if (isEVDatePlugin(rule)) {
			const picker = getPicker(rule)
			return picker.getDate(options.format) !== '' ? true : ['string_empty']
		}
	}

	/**
	 * Retrieves a picker for the given input
	 *
	 * @param {object} rule - `Rule` object from jQuery-Querybuilder
	 * @returns {Datepicker|undefined} Datepicker instance, if one exists for the `input.name`
	 */
	function getPicker(rule) {
		const input = rule.$el.find('input').get(0)
		return document.querySelector(`[name="${input.name}"]`)?.datepicker
	}
}

/**
 * Registers the custom datepicker plugin.
 * ! This is called in `main.onEveryPage()` !
 */
export function registerDatepickerPlugin() {
	$.fn.queryBuilder.define('ev_date_plugin', datepickerPlugin, {
		/* optional defaults */
		container: '#filter',
		format: 'dd.mm.yyyy',
		buttonClass: 'btn',
	})
}

/**
 * Enables datemask and datepicker in queryBuilder
 *
 * @deprecated Datepicker is now supported with the plugin `datepickerPlugin`, ev_date_plugin is automaticly added in function createQueryBuilder
 */
export function enableDateTimeFilter() {
	$('#builder').on('afterUpdateRuleFilter.queryBuilder', (event, rule) => {
		createDefaultDatepicker('#filter .datemask', '#filter')
	})
}

/**
 * Enables auto complete fields in queryBuilder
 *
 * @param {string} fieldSelector - selector to find the correct rule field
 * @param {object} root0 - typeahead configuration
 * @param {string} root0.name - typeahead name
 * @param {boolean} [root0.async=true] - use typeahead async; default: true
 * @param {number?} [root0.limit=100] - typeahead limt; default: 100
 * @param {object} root0.data - typeahead data
 * @param {string} [root0.displayAttr='name'] - The name of the attribute to display the suggestion
 * @param {object} root1 - autoCompleteLabel configuration
 * @param {string} root1.labelMainProperty - autoCompleteLabel main property setting
 * @param {Array} root1.labelProperties - autoCompleteLabel label propery setting
 */
export function enableAutoCompleteLabel(
	fieldSelector,
	{ name, async = true, limit = 100, data, displayAttr = 'name' } = {},
	{ labelMainProperty, labelProperties } = {}
) {
	$('#builder').on('afterUpdateRuleFilter.queryBuilder', (event, rule) => {
		rule.$el.find(fieldSelector).typeahead(
			{ hint: true, highlight: true, minLength: 2 },
			{
				name: name,
				async: async,
				limit: limit,
				source: data,
				templates: {
					suggestion: (suggestion) => {
						return createAutocompleteLabel(
							suggestion,
							labelMainProperty,
							labelProperties
						)
					},
				},
				display: (suggestion) => {
					return suggestion[displayAttr]
				},
			}
		)
		rule.$el
			.find(fieldSelector)
			.bind('typeahead:select typeahead:autocomplete', (ev, suggestion) => {
				rule.$el.find('input.hidden').val(suggestion.id)
			})
	})
}

/**
 * Utility that enables SelectUs fields to be filled correctly in the queryBuilder
 *
 * Enable this _before_ using `.setRules()`.
 */
export function enableSelectUsValueSetter() {
	const selectUsInputTypes = [
		FILTER_INPUT_TYPE.SINGLE_SELECT,
		FILTER_INPUT_TYPE.MULTI_SELECT,
	]
	const isSelectUs = (inputType) => selectUsInputTypes.includes(inputType)
	$('#builder').on(
		'afterUpdateRuleValue.queryBuilder.filter',
		function (event, rule, previousValue) {
			if (!rule) return
			if (isSelectUs(rule.filter.input)) {
				const selectUs = document
					.getElementById(rule.id)
					?.querySelector('select-us')
				if (!selectUs) return
				if (rule.value && rule.value !== previousValue) {
					selectUs.setSelection(rule.value?.toString(), false)
				}
			}
		}
	)
}

/**
 * Adds a date range to the filters
 *
 * @param {string} filterType - indicator of where the filter is stored (e.g. `'eventFilter'`, `'userFilter'`)
 * @param {string} start - string representation of the start date
 * @param {string} end - string representation of the end date
 * @param {string} [startSelector] - [default: `'#dateFilterStart'`]
 * @param {string} [endSelector] -  [default: `'#dateFilterEnd'`]
 */
export function addDateRangeToFilters(
	filterType,
	start,
	end,
	startSelector = '#dateFilterStart',
	endSelector = '#dateFilterEnd'
) {
	document.querySelector(startSelector).value = start
	document.querySelector(endSelector).value = end
	addToFilter(filterType, [
		['date', 'date', 'greater_or_equal', start, true],
		['date', 'date', 'less_or_equal', end, false],
	])
}

/**
 * Adds a filter to the current filters
 *
 * @param {string} filterType - indicator of where the filter is stored (e.g. `'eventFilter'`, `'userFilter'`)
 * @param {Array} filters - queryBuilder filter array
 * @param {Function} callbackFunction - A optional anonymous function that is called when this method is done
 * @param {string} condition - wether to set the filter condition to AND or OR
 * @param {string} customFilterMessage - (optional) if we need a different text in filterNotice
 */
export async function addToFilter(
	filterType,
	filters,
	callbackFunction,
	condition = 'AND',
	customFilterMessage = undefined
) {
	let filter = $('#builder').queryBuilder('getRules')
	filters.forEach((row) => {
		// Ignores all further array entries after the first 5
		const [id, type, operator, value, override] = row
		const rule = {
			id: id,
			field: id,
			type: type,
			operator: operator,
			value: value,
		}

		if (isEmptyOrUndefined(filter) || filter.condition === undefined) {
			filter = { condition: 'AND', rules: [rule] }
		} else {
			if (override) {
				// Remove all rules that share an id with the ones from `filters`
				filter.rules = filter.rules.filter(
					(currentRule) => currentRule.id !== id
				)
			}
			if (operator !== undefined) {
				// operator === undefined ⇒ delete rule
				// I don't know if this case is actually possible, but I keep it in to be in line with legacy
				filter.rules.push(rule)
			}
		}
		if (filter.rules.length === 0) {
			$('#builder').queryBuilder('reset')
		} else {
			filter.condition = condition
			$('#builder').queryBuilder('setRules', filter)
		}
	})

	await saveFilter(
		filterType,
		filter.rules.length === 0,
		undefined,
		customFilterMessage
	)
	if (callbackFunction) {
		callbackFunction()
	}
	// saveCustomFilter(filterType)
	// "done" had reload(), but I don't know if we should keep that
}

/**
 * Removes a filter from the current filters
 *
 * @param {string} filterType - indicator of where the filter is stored (e.g. `'eventFilter'`, `'userFilter'`)
 * @param {Array} filters - queryBuilder filter array
 * @param {string} customFilterMessage - (optional) if we need a different text in filterNotice
 */
export async function removeFilter(
	filterType,
	filters,
	customFilterMessage = undefined
) {
	let filter = $('#builder').queryBuilder('getRules')

	filters.forEach((row) => {
		const [id, type, operator, value] = row // next value would be override
		const rules = {
			id: id,
			field: id,
			type: type,
			operator: operator,
			value: value,
		}

		if (
			filter &&
			filter.condition !== undefined &&
			filter.rules !== undefined
		) {
			const filterRules = []
			filter.rules.forEach((rule) => {
				if (rule.id === id && rule.value === rules.value) {
					filterRules.push(undefined) // sets entries to undefined
				} else {
					filterRules.push(rule)
				}
			})
			filter.rules = filterRules
			let index = filter.rules.indexOf(undefined)

			while (index !== -1) {
				filter.rules.splice(index, 1)
				index = filter.rules.indexOf(undefined)
			}
		} else {
			filter = { condition: 'AND', rules: [rules] }
		}

		if (filter && filter.rules.length === 0) {
			$('#builder').queryBuilder('reset')
		} else {
			$('#builder').queryBuilder('setRules', filter)
		}
	})

	const data = {}
	data.filterModel = JSON.stringify(filter)

	await saveFilter(
		filterType,
		filter.rules.length === 0,
		undefined,
		customFilterMessage
	)
}
