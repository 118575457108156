const ENTITY_MAP = Object.freeze({
	// '&': '&amp;', see #10224
	'<': '&lt;',
	'>': '&gt;',
	'"': '&quot;',
	"'": '&#39;',
	'/': '&#x2F;',
	'`': '&#x60;',
	'=': '&#x3D;',
})

const LINK_ENTITY_MAP = Object.freeze({
	'<a': '&lt;a',
	'</a>': '&lt;&#x2F;a&gt;',
})

/**
 * Replaces special characters in a string with their ASCII equivalents.
 *
 * @param {string} text - The text to process.
 * @returns {string} The processed text with special characters replaced.
 */
export function replaceSpecialChars(text) {
	const specialCharMap = {
		Ș: 'S',
		ș: 's',
		Ț: 'T',
		ț: 't',
		Ă: 'A',
		ă: 'a',
		Â: 'A',
		â: 'a',
		Î: 'I',
		î: 'i',
	}

	return text
		.split('')
		.map((char) => specialCharMap[char] || char)
		.join('')
}

/**
 * Escapes HTML in the given string
 *
 * @param {string} string - string that may contain HTMl tags
 * @returns {string} `string` but with characters that make up HTML tags correctly esacped
 */
export function escapeHtml(string) {
	if (!string) return string
	return String(string).replace(/[<>"'`=/]/g, function (s) {
		return ENTITY_MAP[s]
	})
}

/**
 * Escapes all hyperlink tags in the given string
 * This is needed in some rare special cases were we allow the users to do some HTML but we want them to do no scary thinks
 *
 * @param {string} string - String that may contain HTML link tags
 * @returns {string} - A string containing the same as the given string but with escaped html hyperlinks
 */
export function escapeLinks(string) {
	if (!string) return string
	if (!string.includes('<a')) return string
	const replaceThis = (s) => {
		return LINK_ENTITY_MAP[s]
	}
	return String(string).replace('<a', replaceThis).replace('</a>', replaceThis)
}

/**
 * Takes a string with correctly escaped characters of HTML tags and replaces them with their ASCII characters
 *
 * @param {string} string - string that may contain escaped characters
 * @returns {string} `string` but without escaped characters
 */
export function unescapeHtml(string) {
	const e = document.createElement('textarea')
	e.innerHTML = string
	// handle case of empty string
	return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
}

/**
 * Takes a string with (un)escaped HTML tags and removes them
 *
 * @param {string} string - string to process
 * @param {boolean} [escapeAgain] - if true the result will escape characters of HTML tags again [default: false]
 * @returns {string} human readable `string`
 */
export function makeHtmlHumanReadable(string, escapeAgain = false) {
	if (!string) {
		return ''
	}

	string = unescapeHtml(string)
	string = string.replace(/<.*?>/g, '')

	return escapeAgain === false ? string : escapeHtml(string)
}

/**
 * decode encoded unicode chars
 *
 * @param {string} text - text to decode
 * @returns {string} - decoded text
 */
export function decodeUnicode(text) {
	return text.replace(/\\u([\dA-F]{4})/gi, (match, grp) => {
		return String.fromCharCode(parseInt(grp, 16))
	})
}
