'use strict'
/**
 * This file is only for the default CRUD function wrappers for the API.
 * If you have certain API calls, that you use over and over, and want them as an easy function,
 * just create another file in this folder and import the functions from this file.
 *
 * !! Dont forget to import your new file in the `footer.html` (this has to be done until we do bundling) !!
 *
 * Basic usage:
 * If you're in an async function you can just use `result = await get('member');`.
 * If you're not, use Promises: `get('member').then((result) => {});`
 */
import {
	headers,
	checkCondition,
	isEmptyOrUndefined,
	mapFilter,
	stringifyData,
	handleResponse,
	addAbortController,
	removeAbortController,
	abortAbortController,
} from '../utils/helpers/apiHelpers'
import { log } from '../globals/logger'

export const VERSION = 'latest'

// Cache for requests that will last for 5 seconds to prevent hitting the exact same API call multible times on the init of most pages
const cachedResponses = {}

/**
 * Function wrapper for API GET requests.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string} fields - Django-restql query string, defaults to `"{\*}"`
 * @param {object} filter - value filters as an object of key-value-pairs
 * @param {string|number} limit - how many entries to return per page
 * @param {string|number} page - number of the page to retrieve
 * @param {string} order - sorting of the returned data
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @param {Map} queryParams - the params for the query
 * @param {string} signalId - id for an AbortController
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function get(
	endpoint,
	fields = '{*}',
	filter = {},
	limit = '',
	page = '1',
	order = '',
	showError = true,
	queryParams = {},
	signalId
) {
	const strfilter = JSON.stringify(filter)
	log('GET ' + endpoint + ': ' + strfilter)
	checkCondition(endpoint === undefined, 'GET requires an Endpoint', showError)

	const mappedFilters = mapFilter(filter)
	queryParams = new Map(Object.entries(queryParams))
	const url = `/api/${VERSION}/${endpoint}${
		queryParams.size > 0
			? `/?${Array.from(queryParams)
					.map((arr) => arr.join('='))
					.join('&')}&`
			: '/?'
	}page=${encodeURIComponent(page)}&limit=${encodeURIComponent(
		limit
	)}&ordering=${encodeURIComponent(order)}&query=${encodeURIComponent(
		fields
	)}${mappedFilters}`
	const cacheKey = `${endpoint}${fields}${strfilter}${limit}${page}${order}${JSON.stringify(
		queryParams
	)}`
	if (signalId || cachedResponses[cacheKey] === undefined) {
		// Set the cached response so other calls of this exact same request will not
		// trigger the fetch again but just wait for the first one to finish
		cachedResponses[cacheKey] = null
		const controller = addAbortController(signalId)
		const rawResponse = await fetch(url, { signal: controller?.signal })
		removeAbortController(signalId)

		const response = await handleResponse(rawResponse, showError)
		cachedResponses[cacheKey] = response
	}

	// Returning a promise that will wait until the first instance of the exact same request is finished (saving API hits)
	return new Promise((resolve) => {
		const interval = setInterval(() => {
			if (cachedResponses[cacheKey] !== null) {
				clearInterval(interval)
				clearCachedResponse(cacheKey)
				resolve(cachedResponses[cacheKey])
			}
		}, 100)
	})
}

/**
 * Clearing the cached response after 5 seconds to make it possible to refresh the data
 *
 * @param {string} cacheKey - The key of the cached request
 */
function clearCachedResponse(cacheKey) {
	setTimeout(() => delete cachedResponses[cacheKey], 5000)
}

/**
 * Clears the cache for a whole endpoint.
 * Avoiding using the cache when data were changed so the changed data gets requested
 *
 * @param {string} endpoint - The endpoint to delete the cache for
 */
function clearCacheForEndpoint(endpoint) {
	for (const cacheKey of Object.keys(cachedResponses)) {
		if (cacheKey.startsWith(endpoint)) {
			delete cachedResponses[cacheKey]
		}
	}
}

/**
 * Function wrapper for API GET requests that allow for easy retrieval of `next`/`previous` urls.
 *
 * @param {string} url - url complete with all query parameters
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @param {string} signalId - id for an AbortController
 * @returns {Promise} promise of an object with status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function getByUrl(url, showError = true, signalId) {
	log(url)
	checkCondition(
		url === undefined || url === '',
		'Requires a valid url',
		showError
	)
	const controller = addAbortController(signalId)
	const response = await fetch(url, { signal: controller?.signal })
	removeAbortController(signalId)
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for API GET requests of one instance
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string|number} id - id of the instance
 * @param {string} fields - Django-restql query string, defaults to `"{\*}"`
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @param {string} signalId - id for an AbortController
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function getOne(
	endpoint,
	id,
	fields = '{*}',
	showError = true,
	signalId
) {
	log('GET ' + endpoint + ': pk=' + id)
	checkCondition(
		endpoint === undefined || id === undefined,
		'GET requires an "endpoint", and a "id"',
		showError
	)

	const url = `/api/${VERSION}/${endpoint}/${encodeURIComponent(
		id
	)}/?query=${encodeURIComponent(fields)}`
	const controller = addAbortController(signalId)
	const response = await fetch(url, { signal: controller?.signal })
	removeAbortController(signalId)

	return await handleResponse(response, showError)
}

/**
 * Function wrapper for API POST requests.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {object} data - object of the data to post
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing or data is empty
 */
export async function post(endpoint, data, showError = true) {
	log('POST ' + endpoint + ': ' + JSON.stringify(data))

	checkCondition(
		endpoint === undefined || isEmptyOrUndefined(data),
		'POST requires an "endpoint", and non-empty "data"',
		showError
	)

	const url = `/api/${VERSION}/${endpoint}/`
	const jsonData = stringifyData(data, showError)
	if (jsonData === null) {
		return { status: 400, data: null }
	}

	const response = await fetch(url, {
		method: 'POST',
		mode: 'same-origin',
		headers: headers(),
		body: jsonData,
	})
	clearCacheForEndpoint(endpoint)
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for API POST requests for beta versions of the API.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {object} data - object of the data to post
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing or data is empty
 */
export async function postBeta(endpoint, data, showError = true) {
	log('POST ' + endpoint + ': ' + JSON.stringify(data))

	checkCondition(
		endpoint === undefined || isEmptyOrUndefined(data),
		'POST requires an "endpoint", and non-empty "data"',
		showError
	)

	const url = `/api/v1.7/${endpoint}/`
	const jsonData = stringifyData(data, showError)
	if (jsonData === null) {
		return { status: 400, data: null }
	}

	const response = await fetch(url, {
		method: 'POST',
		mode: 'same-origin',
		headers: headers(),
		body: jsonData,
	})
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for API PATCH requests for beta versions of the API.
 * __Direct usage is discouraged__
 *
 * @param {string} method - HTTP method to use, should be `PUT` or `PATCH`
 * @param {string} endpoint - name of the API endpoint
 * @param {string|number} id - id of the instance
 * @param {object} data - object of the data to POST
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing or data is empty
 */
async function changeInstanceBeta(
	method,
	endpoint,
	id,
	data,
	showError = true
) {
	log(method + ' ' + endpoint + ': ' + JSON.stringify(data))
	checkCondition(
		method === undefined ||
			endpoint === undefined ||
			id === undefined ||
			isEmptyOrUndefined(data),
		`${
			method || 'change_instance'
		} requires a "method", an "endpoint", a "id", and non-empty "data"`,
		showError
	)

	const url = `/api/v1.7/${endpoint}/${encodeURIComponent(id)}`
	const jsonData = stringifyData(data, showError)
	if (jsonData === null) {
		return { status: 400, data: null }
	}

	const response = await fetch(url, {
		method: method,
		mode: 'same-origin',
		headers: headers(),
		body: jsonData,
	})
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for API PATCH requests for beta versions of the API.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string|number} id - id of the instance
 * @param {object} data - object of the data to PATCH
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing or data is empty
 */
export async function patchBeta(endpoint, id, data, showError = true) {
	return await changeInstanceBeta('PATCH', endpoint, id, data, showError)
}

/**
 * Function wrapper for API PUT or PATCH requests.
 * __Direct usage is discouraged__
 *
 * @param {string} method - HTTP method to use, should be `PUT` or `PATCH`
 * @param {string} endpoint - name of the API endpoint
 * @param {string|number} id - id of the instance
 * @param {object} data - object of the data to POST
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing or data is empty
 */
async function changeInstance(method, endpoint, id, data, showError = true) {
	log(method + ' ' + endpoint + ': ' + JSON.stringify(data))
	checkCondition(
		method === undefined ||
			endpoint === undefined ||
			id === undefined ||
			isEmptyOrUndefined(data),
		`${
			method || 'change_instance'
		} requires a "method", an "endpoint", a "id", and non-empty "data"`,
		showError
	)

	const url = `/api/${VERSION}/${endpoint}/${encodeURIComponent(id)}`
	const jsonData = stringifyData(data, showError)
	if (jsonData === null) {
		return { status: 400, data: null }
	}

	const response = await fetch(url, {
		method: method,
		mode: 'same-origin',
		headers: headers(),
		body: jsonData,
	})
	clearCacheForEndpoint(endpoint)
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for API PUT requests.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string|number} id - id of the instance
 * @param {object} data - object of the data to PUT
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing or data is empty
 */
export async function put(endpoint, id, data, showError = true) {
	return await changeInstance('PUT', endpoint, id, data, showError)
}

/**
 * Function wrapper for API PATCH requests.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string|number} id - id of the instance
 * @param {object} data - object of the data to PATCH
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing or data is empty
 */
export async function patch(endpoint, id, data, showError = true) {
	return await changeInstance('PATCH', endpoint, id, data, showError)
}

/**
 * Uploading a file to the API
 * NOTE: To upload a file, the entry for which the file is intended must exist - POSTing a file is not possible
 *
 * @param {string} endpoint - The name of the API endpoint
 * @param {string|number} id - The id of the instance
 * @param {File} file - The file object to upload
 * @param {string} fieldName - The fieldName of the uploading file
 * @param {boolean} showError - (optional) Showing "api_error" if set to true
 * @returns {Promise} Promise of an object with the status of the response and the data as an object
 * @throws If necessary arguments are missing
 */
export async function uploadFile(
	endpoint,
	id,
	file,
	fieldName,
	showError = true
) {
	checkCondition(
		file === undefined ||
			endpoint === undefined ||
			id === undefined ||
			fieldName === undefined,
		'uploadFile requires a "endpoint", an "id", a "file", and "fieldName"',
		showError
	)

	// The header must be modified so the API gets all information about the file to upload
	const header = headers()
	delete header['Content-Type']

	// Place the file inside a FormData - this is the only way the API recognize the uploaded file
	const fileFormData = new FormData()
	fileFormData.append(fieldName, file)

	const url = `/api/${VERSION}/${endpoint}/${encodeURIComponent(id)}`
	clearCacheForEndpoint(endpoint)
	return await fetch(url, {
		method: 'PATCH',
		body: fileFormData,
		headers: header,
	})
}

/**
 * Function wrapper for API DELETE requests.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string|number} id - id of the instance
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function delete_(endpoint, id, showError = true) {
	checkCondition(
		endpoint === undefined || id === undefined,
		'DELETE requires an "endpoint", and an "id"',
		showError
	)

	const url = `/api/${VERSION}/${endpoint}/${encodeURIComponent(id)}/`

	const response = await fetch(url, {
		method: 'DELETE',
		mode: 'same-origin',
		headers: headers(),
	})

	clearCacheForEndpoint(endpoint)
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for API DELETE requests for beta versions of the API.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string|number} id - id of the instance
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function deleteBeta_(endpoint, id, showError = true) {
	checkCondition(
		endpoint === undefined || id === undefined,
		'DELETE requires an "endpoint", and an "id"',
		showError
	)

	const url = `/api/v1.7/${endpoint}/${encodeURIComponent(id)}/`

	const response = await fetch(url, {
		method: 'DELETE',
		mode: 'same-origin',
		headers: headers(),
	})

	return await handleResponse(response, showError)
}

/**
 * Function wrapper for GET Action requests to a whole endpoint.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string} actionName - name of the Action
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function endpointGetAction(
	endpoint,
	actionName,
	showError = true
) {
	checkCondition(
		endpoint === undefined || actionName === undefined,
		'GET action for an endpoint requires an "endpoint", and an "action_name"',
		showError
	)

	const url = `/api/${VERSION}/${endpoint}/${actionName}/`

	const response = await fetch(url)
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for POST Action requests to a whole endpoint.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string} actionName - name of the action
 * @param {object} data - object of the data to POST
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function endpointPostAction(
	endpoint,
	actionName,
	data,
	showError = true
) {
	checkCondition(
		endpoint === undefined ||
			actionName === undefined ||
			isEmptyOrUndefined(data),
		'POST action for an endpoint requires an "endpoint", an "action_name", and non-empty "data"',
		showError
	)

	const url = `/api/${VERSION}/${endpoint}/${actionName}/`
	const jsonData = stringifyData(data, showError)
	if (jsonData === null) {
		return { status: 400, data: null }
	}

	const response = await fetch(url, {
		method: 'POST',
		mode: 'same-origin',
		headers: headers(),
		body: jsonData,
	})
	clearCacheForEndpoint(endpoint)
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for GET Action requests to an instance.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string} actionName - name of the action
 * @param {string|number} id - id of the instance
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function instanceGetAction(
	endpoint,
	actionName,
	id,
	showError = true
) {
	checkCondition(
		endpoint === undefined || actionName === undefined || id === undefined,
		'POST action for an endpoint requires an "endpoint", an "action_name", and an "id"',
		showError
	)

	const url = `/api/${VERSION}/${endpoint}/${encodeURIComponent(
		id
	)}/${actionName}/`

	const response = await fetch(url)
	return await handleResponse(response, showError)
}

/**
 * Function wrapper for POST Action requests to an instance.
 *
 * @param {string} endpoint - name of the API endpoint
 * @param {string} actionName - name of the action
 * @param {string|number} id - id of the instance
 * @param {object} data - object of the data to POST
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise} promise of an object with the status of the response and the data as an object
 * @throws if necessary arguments are missing
 */
export async function instancePostAction(
	endpoint,
	actionName,
	id,
	data,
	showError = true
) {
	checkCondition(
		endpoint === undefined ||
			actionName === undefined ||
			id === undefined ||
			isEmptyOrUndefined(data),
		'POST action for an endpoint requires an "endpoint", an "action_name", an "id", and non-empty "data"',
		showError
	)

	const url = `/api/${VERSION}/${endpoint}/${encodeURIComponent(
		id
	)}/${actionName}/`
	const jsonData = stringifyData(data, showError)
	if (jsonData === null) {
		return { status: 400, data: null }
	}
	const response = await fetch(url, {
		method: 'POST',
		mode: 'same-origin',
		headers: headers(),
		body: jsonData,
	})

	return await handleResponse(response, showError)
}

/**
 * Triggers the cancel signal on an AbortController and removes it
 *
 * @param {string} id - id for an AbortController
 * @returns {boolean} true if the signal was triggered, else false
 */
export const cancelRequest = (id) => {
	console.warn(`The request "${id}" was cancelled!`)
	return abortAbortController(id) && removeAbortController(id)
}
