import Bloodhound from 'corejs-typeahead'
import $ from 'jquery'
import { get } from '../api/crud'
import { addTooltips } from './bootstrap/tooltip'
import { mapFilter } from './helpers/apiHelpers'

import { escapeHtml } from './html'
import { gettext } from './translation'
import moment from 'moment'

/**
 * Create's an typeahead for an input field.
 *
 * @param {object} element - selector or element
 * @param {string} lable - lable
 * @param {Function} callback - callback function
 * @param {string} entity - model name or endpoint name
 * @param {Array} fields - model attributes
 * @param {Array} fieldLables - label attributes, optional
 * @param {string} seperator - seperator, optional
 * @param {boolean} useApi - Using the new API
 * @param {Function} transform - Bloodhound remote.transform function
 * @param {object} filter - The filters for the API
 * @param {string} excludeId - Id which should be excluded from typeAhead (normaly used for me or current user PK/ID)
 * @param {string} rootRelationsExclude - id from the user which relations should be removed from the typeaheads response
 * @param {string} customLabel - custom label
 * @param {boolean} disableCaching - disable the typeAhead caching, so that each time something is typed a request is sent
 */
export function createTypeahead(
	element,
	lable,
	callback,
	entity,
	fields,
	fieldLables = null,
	seperator = ',',
	useApi = false,
	transform = (data) => {
		return data
	},
	filter = {},
	excludeId = null,
	rootRelationsExclude = null,
	customLabel = undefined,
	disableCaching = false
) {
	let url = `/app/api/suggest/${entity}/?query=%QUERY&fields=${fields}`
	if (excludeId) {
		url += `&exclude=${excludeId}`
	}
	if (rootRelationsExclude) {
		url += `&rootRelationsExclude=${rootRelationsExclude}`
	}
	if (useApi) {
		const query = fields.join(',')
		const mappedFilter = mapFilter(filter)
		url = `/api/latest/${entity}?search=%QUERY&query={${query}}&limit=10${mappedFilter}`
	}
	const remoteArgs = {
		url: url,
		wildcard: '%QUERY',
		transform: (data) => {
			return transform(data)
		},
	}
	if (disableCaching) remoteArgs.cache = false
	const bloodhound = new Bloodhound({
		datumTokenizer: Bloodhound.tokenizers.whitespace,
		queryTokenizer: Bloodhound.tokenizers.whitespace,
		remote: remoteArgs,
	})

	markAsRequired($(element).get(0))

	$(element).typeahead(
		{ hint: true, highlight: true, minLength: 2 },
		{
			name: gettext(lable),
			value: name,
			async: true,
			limit: 100,
			source: bloodhound,
			templates: {
				suggestion: (suggestion) => {
					return createLable(
						suggestion,
						fieldLables || fields,
						seperator,
						undefined,
						customLabel
					)
				},
			},
			display: (suggestion) => {
				return suggestion.name
			},
		}
	)

	$(element).on('typeahead:select typeahead:autocomplete', callback)
}

/**
 * Create's an typeahead for an input field.
 *
 * @param {object} element - selector or element
 * @param {string} lable - lable
 * @param {Function} callback - callback function
 * @param {Array} sourceDefinitions - source definitions			example: [{ entity: 'contact-details-group', fields: ['id', 'name', 'short'], fieldLables: ['name', 'short']] }, { ... }]
 * @param {string} seperator - seperator, optional
 * @param {Function} transform - Bloodhound remote.transform function
 * @param {object} filter - The filters for the API
 */
export function createMultiSourceTypeahead(
	element,
	lable,
	callback,
	sourceDefinitions,
	seperator = ',',
	transform = (data) => {
		return data
	},
	filter = {}
) {
	const sources = []
	sourceDefinitions.forEach((sourceDefinition) => {
		let source
		if (sourceDefinition.useBloodhound) {
			const query = sourceDefinition.fields.join(',')
			const mappedFilter = mapFilter(filter)
			const url = `/api/latest/${sourceDefinition.entity}?search=%QUERY&query={${query}}&limit=10${mappedFilter}`
			source = new Bloodhound({
				datumTokenizer: Bloodhound.tokenizers.whitespace,
				queryTokenizer: Bloodhound.tokenizers.whitespace,
				remote: {
					url: url,
					wildcard: '%QUERY',
					transform: (data) => {
						return transform(data, sourceDefinition.entity)
					},
				},
			})
		} else {
			source = sourceDefinition.source
		}

		sources.push({
			name: gettext(lable),
			value: name,
			async: true,
			limit: 100,
			source: source,
			templates: {
				suggestion: (suggestion) => {
					return createLable(
						suggestion,
						sourceDefinition.fieldLables || sourceDefinition.fields,
						seperator,
						sourceDefinition.entity
					)
				},
			},
			display: (suggestion) => {
				return suggestion.name
			},
		})
	})

	markAsRequired($(element).get(0))
	$(element).typeahead({ hint: true, highlight: true, minLength: 2 }, sources)

	$(element).on('typeahead:select typeahead:autocomplete', callback)
}

/**
 * Create's an element for displaying the result.
 *
 * @param {object} suggestion - suggestion
 * @param {Array} fields - label attributes
 * @param {string} seperator - seperator
 * @param {string} endpoint - the current endpoint
 * @param {string} customLabel - custom label
 * @returns {string} - html string
 */
function createLable(
	suggestion,
	fields,
	seperator,
	endpoint = undefined,
	customLabel = undefined
) {
	let info = ''
	let endpointName

	switch (endpoint) {
		case 'contact-details':
			endpointName = suggestion.member
				? gettext('Mitglied')
				: gettext('Adresse')
			break
		case 'contact-details-group':
			endpointName = gettext('Adressgruppe')
			break
		case 'member-group':
			endpointName = gettext('Mitgliedsgruppe')
			break
		case 'externalUser':
			endpointName = gettext('externalUser')
			break
		default:
			endpointName = ''
	}

	for (let i = 1; i < fields.length; i++) {
		let value = ''

		if (Array.isArray(fields[i])) {
			if (fields[i].length === 3) {
				// fields[i][0] must be a bool value from API. True: Insert fields[i][1], False: insert field[i][2]
				const firstEntry = suggestion[fields[i][0]]
				if (firstEntry === 'true' || firstEntry === true) {
					value = fields[i][1] || ''
				}
				if (firstEntry === 'false' || firstEntry === false) {
					value = fields[i][2] || ''
				}
			} else {
				value = suggestion[fields[i][0]] || fields[i][1] || ''
			}
		} else {
			value = suggestion[fields[i]]
		}

		if (info === '') {
			info = escapeHtml(value)
		} else {
			info += `${seperator} ${escapeHtml(value)}`
		}
	}

	if (suggestion.member && suggestion.member.resignationDate) {
		const resignationDate = moment(
			suggestion.member.resignationDate,
			'YYYY-MM-DDTHH:mm'
		)
		const now = new Date()
		if (now >= resignationDate) {
			endpointName = gettext('Ehemaliges Mitglied')
		}
	}
	if (endpointName !== undefined && endpointName !== '') {
		endpointName = `(${endpointName})`
	}

	let html = ''
	if (customLabel === 'appAddressbookMailings') {
		html = `<div class="cursor-pointer">${escapeHtml(
			suggestion[fields[0]]
		)} ${endpointName} <div class="float-end"><small>${info}</small><a class="addAddressToIgnore text-danger ms-2" data-id="${
			suggestion.id
		}" data-bs-toggle="tooltip" title="${gettext(
			'Diese Adresse ausschließen'
		)}"><i class="far fa-user-times"></i></a></div></div>`
	} else if (customLabel === 'memberListSearch') {
		html = `<div class="cursor-pointer">${escapeHtml(
			suggestion[fields[0]]
		)}</div>`
	} else {
		html = `<div class="cursor-pointer">${escapeHtml(
			suggestion[fields[0]]
		)} ${endpointName} <small class="float-end">${info}</small></div>`
	}
	return html
}

/**
 * Creates an typeahead for user search
 *
 * @param {string} selector - The selector of the typeahead input to create the Typeahead for
 * @param {boolean|undefined} _isCompany - (optional) Search only for companies - set to undefined to search both
 * @param {boolean|undefined} _isChairman - (optional) Search only for admin users - set to undefined to search both
 * @param {number|undefined} memberGroups - (optional) Search only for members in specific group - set to undefined to search in every group
 * @param {boolean|undefined} hasLeft - (optional) Search only for resigned members - set to undefined to search both
 * @param {boolean|undefined} memberIsNull - (optional) Search only for addresses with a linked member - set to undefined to search both
 * @param {boolean|undefined} isApplication - (optional, defaults to undefined) Search only for non application members - set to undefined to search both
 * @param {object} [additionalFilter={}] - (optional) additional filters (for example module permission filtering)
 * @param {Function} callbackFuntion - a function that is called when you select a typeahead
 * @param {string} customLabel - custom label
 */
export function memberSearch(
	selector,
	_isCompany,
	_isChairman,
	memberGroups,
	hasLeft,
	memberIsNull,
	isApplication = undefined,
	additionalFilter = {},
	callbackFuntion,
	customLabel = undefined
) {
	let lastSuggestionUser
	_checkPermissions(selector)

	const autocomplete = (event, suggestion) => {
		const userSuggestion = event.currentTarget
		userSuggestion.dataset.memberid = suggestion.memberId
		userSuggestion.dataset.addressid = suggestion.id
		userSuggestion.dataset.profilePicture = suggestion._profilePicture
		lastSuggestionUser = userSuggestion.value
		if (callbackFuntion) {
			callbackFuntion([suggestion])
		}
	}

	const transform = (response) => {
		const results = []
		response.results.forEach((address) => {
			let name = address.name
			if (address.member) {
				// Cannot use createLable here because the contact-details endpoint is always used
				name = name + ` (${gettext('Mitglied')})`
			}
			let street = address.street
			let city = address.city
			if (address._isCompany) {
				street = address.companyStreet
				city = address.companyCity
			}
			results.push({
				name: name,
				street: street,
				city: city,
				id: address?.id,
				memberId: address?.member?.id,
				_profilePicture: address?.member?._profilePicture,
				primaryEmail: address?.primaryEmail,
				preferredCommunicationWay: address?.preferredCommunicationWay,
				_isCompany: address?._isCompany,
				balance: address.balance,
				addressStringInvoice: address.addressStringInvoice,
				sendInvoiceCompanyMail: address.sendInvoiceCompanyMail,
				companyEmailInvoice: address.companyEmailInvoice,
			})
		})
		return results
	}

	const filter = { deleted: false }
	if (_isCompany !== undefined) filter._isCompany = _isCompany
	if (_isChairman !== undefined) filter._isChairman = _isChairman
	if (hasLeft !== undefined) filter.has_left = hasLeft
	if (isApplication !== undefined) filter._isApplication = isApplication
	if (memberIsNull !== undefined) {
		filter.member__isnull = memberIsNull
		filter.member__inWastebasket = memberIsNull
	}
	if (memberGroups !== undefined) {
		filter.memberGroups = memberGroups
		$(selector).typeahead('destroy')
	}
	if (Object.keys(additionalFilter).length)
		Object.assign(filter, additionalFilter)

	createTypeahead(
		selector,
		'Mitglieder',
		autocomplete,
		'contact-details',
		[
			'id',
			'addressStringInvoice',
			'name',
			'street',
			'city',
			'primaryEmail',
			'preferredCommunicationWay',
			'_isCompany',
			'balance',
			'member{id, _profilePicture}',
			'companyEmailInvoice',
			'sendInvoiceCompanyMail',
			'companyStreet',
			'companyCity',
		],
		['name', 'street', ['city', gettext('Unbekannter Ort')]],
		',',
		true,
		transform,
		filter,
		null,
		null,
		customLabel
	)

	$(selector).bind('typeahead:change', (event) => {
		const userSuggestion = event.currentTarget
		if (lastSuggestionUser !== userSuggestion.value) {
			userSuggestion.dataset.memberid = ''
			userSuggestion.dataset.addressid = ''
		}
	})
}

/**
 * Update the value of a typeahead field
 *
 * @param {string} selector - The selector of the field to update
 * @param {string} value - The value to update the field with
 */
export function updateValueInTypeahead(selector, value) {
	// This value needs to be set like this, otherwise the field loses its value when it has one directly after opening the modal
	// and one clicks into the field and then clicks elsewhere
	// https://github.com/twitter/typeahead.js/issues/860#issuecomment-48430835
	$(selector).typeahead('val', value)
}

/**
 * Creates an typeahead for location search
 *
 * @param {string} selector - The selector of the typeahead input to create the Typeahead for
 * @param {Function} callbackFuntion - a function that is called when you select a tyepahead
 */
export function locationSearch(selector, callbackFuntion) {
	let lastSuggestionLocation

	const autocomplete = (event, suggestion) => {
		const locationSuggestion = event.currentTarget
		locationSuggestion.dataset.locationid = suggestion.id
		lastSuggestionLocation = locationSuggestion.value
		if (callbackFuntion) {
			callbackFuntion([suggestion])
		}
	}

	const transform = (response) => {
		const results = []
		response.results.forEach((location) => {
			results.push({
				name: location.name,
				street: location.street,
				city: location.city,
				id: location?.id,
			})
		})
		return results
	}

	createTypeahead(
		selector,
		'Orte',
		autocomplete,
		'location',
		['id', 'name', 'street', 'city'],
		[
			'name',
			['street', gettext('Unbekannter Straße')],
			['city', gettext('Unbekannter Ort')],
		],
		',',
		true,
		transform
	)

	$(selector).bind('typeahead:change', (event) => {
		const locationSuggestion = event.currentTarget
		if (lastSuggestionLocation !== locationSuggestion.value) {
			locationSuggestion.dataset.locationid = ''
		}
	})
}

/**
 * Creates an typeahead for article search
 *
 * @param {string} selector - The selector of the typeahead input to create the Typeahead for
 * @param {Function} callbackFuntion - a function that is called when you select a tyepahead
 */
export function articleSearch(selector, callbackFuntion) {
	let lastSuggestionArticle

	const autocomplete = (event, suggestion) => {
		const articleSuggestion = event.currentTarget
		articleSuggestion.dataset.articleid = suggestion.id
		lastSuggestionArticle = articleSuggestion.value
		if (callbackFuntion) {
			callbackFuntion([suggestion], selector)
		}
	}

	const transform = (response) => {
		const results = []
		response.results.forEach((article) => {
			results.push({
				sellingPrice: article.sellingPrice,
				name: article.name,
				description: article.description,
				id: article?.id,
				label: `${article.identifier} ${article.name}`,
			})
		})
		return results
	}

	createTypeahead(
		selector,
		'article-object',
		autocomplete,
		'article-object',
		['id', 'sellingPrice', 'name', 'description', 'identifier'],
		['label', 'sellingPrice'],
		',',
		true,
		transform
	)

	$(selector).bind('typeahead:change', (event) => {
		const articleSuggestion = event.currentTarget
		if (lastSuggestionArticle !== articleSuggestion.value) {
			articleSuggestion.dataset.articleid = ''
		}
	})
}

/**
 * Creates an typeahead for user and adress groups search
 *
 * @param {string} selector - The selector of the typeahead input to create the Typeahead for
 */
export function memberGroupSearch(selector) {
	let lastSuggestionMemberGroups
	_checkPermissions(selector)

	const autocomplete = (event, suggestion) => {
		const memberGroupSuggestion = event.currentTarget
		memberGroupSuggestion.dataset.membergroupid = suggestion.id
		memberGroupSuggestion.dataset.short = suggestion.short
		lastSuggestionMemberGroups = memberGroupSuggestion.value
	}

	const transform = (response) => {
		const results = []
		response.results.forEach((memberGroup) => {
			results.push({
				name: memberGroup.name,
				short: memberGroup.short,
				id: memberGroup?.id,
			})
		})
		return results
	}

	const filter = { deleted: false }

	createTypeahead(
		selector,
		'member-groups',
		autocomplete,
		'contact-details',
		['id', 'name', 'short'],
		['name', 'short'],
		',',
		true,
		transform,
		filter
	)

	$(selector).bind('typeahead:change', (event) => {
		const memberGroupSuggestion = event.currentTarget
		if (lastSuggestionMemberGroups !== memberGroupSuggestion.value) {
			memberGroupSuggestion.dataset.membergroupid = ''
			memberGroupSuggestion.dataset.short = ''
		}
	})
}

/**
 * Creates an typeahead for user and adress groups search
 *
 * @param {string} selector - The selector of the typeahead input to create the Typeahead for
 */
export function adressGroupSearch(selector) {
	let lastSuggestionAdressGroups
	_checkPermissions(selector)

	const autocomplete = (event, suggestion) => {
		const adressGroupSuggestion = event.currentTarget
		adressGroupSuggestion.dataset.adressgroupid = suggestion.id
		adressGroupSuggestion.dataset.short = suggestion.short
		lastSuggestionAdressGroups = adressGroupSuggestion.value
	}

	const transform = (response) => {
		const results = []
		response.results.forEach((adressGroup) => {
			results.push({
				name: adressGroup.name,
				short: adressGroup.short,
				id: adressGroup?.id,
			})
		})
		return results
	}

	const filter = { deleted: false }

	createTypeahead(
		selector,
		'contact-details-groups',
		autocomplete,
		'contact-details',
		['id', 'name', 'short'],
		['name', 'short'],
		',',
		true,
		transform,
		filter
	)

	$(selector).bind('typeahead:change', (event) => {
		const adressGroupSuggestion = event.currentTarget
		if (lastSuggestionAdressGroups !== adressGroupSuggestion.value) {
			adressGroupSuggestion.dataset.membergroupid = ''
			adressGroupSuggestion.dataset.short = ''
		}
	})
}

/**
 * Creates an typeahead for user and adress groups search
 *
 * @param {string} selector - The selector of the typeahead input to create the Typeahead for
 * @param {Function} showRightparticipationInputs -
 */
export function multiSourceSearch(selector, showRightparticipationInputs) {
	let lastSuggestionAdressGroups
	_checkPermissions(selector)

	const autocomplete = (event, suggestion) => {
		const adressGroupSuggestion = event.currentTarget
		adressGroupSuggestion.dataset.Elementid = suggestion.id
		adressGroupSuggestion.dataset.endpoint = suggestion.endpoint
		lastSuggestionAdressGroups = adressGroupSuggestion.value
		if (showRightparticipationInputs) {
			showRightparticipationInputs()
		}
	}

	const transform = (response, endpoint) => {
		const results = []
		response.results.forEach((searchResults) => {
			searchResults.endpoint = endpoint
			results.push(searchResults)
		})
		return results
	}

	const filter = { deleted: false }

	const sourceDefinitions = [
		{
			entity: 'contact-details',
			fieldLables: ['name', 'street', ['city', gettext('Unbekannter Ort')]],
			fields: ['id', 'member{id,resignationDate}', 'name', 'street', 'city'],
			useBloodhound: true,
		},
		{
			entity: 'member-group',
			fieldLables: ['name', 'short'],
			fields: ['id', 'name', 'short'],
			useBloodhound: true,
		},
		{
			entity: 'contact-details-group',
			fieldLables: ['name', 'short'],
			fields: ['id', 'name', 'short'],
			useBloodhound: true,
		},
		{
			source: (query, syncResults) => {
				syncResults([
					{
						name: gettext('Person als Begleitperson anmelden'),
						endpoint: 'externalUser',
					},
				])
			},
			fieldLables: ['name'],
		},
	]
	createMultiSourceTypeahead(
		selector,
		'MultiSource',
		autocomplete,
		sourceDefinitions,
		',',
		transform,
		filter
	)

	$(selector).bind('typeahead:change', (event) => {
		const adressGroupSuggestion = event.currentTarget
		if (lastSuggestionAdressGroups !== adressGroupSuggestion.value) {
			adressGroupSuggestion.dataset.membergroupid = ''
			adressGroupSuggestion.dataset.short = ''
		}
	})
}

/**
 * Checks if the user may not access all member data (The member search can be broken/restricted)
 *
 * @param {string} selector - The selector of the member search input
 */
async function _checkPermissions(selector) {
	const permissionsResponse = await get(
		'member/me/permissions',
		'',
		{},
		'',
		'1',
		'',
		false
	)
	if (permissionsResponse.status === 200) {
		const permissions = permissionsResponse.data
		// The chairman is trated like a normal user
		if (
			permissions.module_members === 'n' ||
			permissions.module_addresses === 'n'
		) {
			_setRestrictedWarning(selector)
		}
	}
}

/**
 * Sets the warning triangle and tooltip text for the member search to inform the user that the search results might not show all members
 *
 * @param {string} selector - The selector of the member search input
 */
function _setRestrictedWarning(selector) {
	const input = document.querySelector(selector)
	const container = input.parentElement.parentElement
	const title = gettext(
		'Eingeschränkte Mitgliedersuche (nicht alle Mitglieder können gesehen werden)'
	)
	const triangleIcon = `
		<div class="position-absolute me-2 start-97-5 top-50 translate-middle">
			<i class="far fa-exclamation-triangle text-warning"></i>
		</div>
	`

	container.classList.add('input-group-append', 'position-relative')
	container.dataset.bsToggle = 'tooltip'
	container.title = title

	// Using insertAdjacentHTML to not remove the event listeners of the input
	input.insertAdjacentHTML('afterend', triangleIcon)
	addTooltips('[data-bs-toggle="tooltip"]:not([data-bs-original-title])')
}

/**
 * Adds the .required-typeahead CSS class to work around our input[required] logic not working with typeaheads
 *
 * @param {HTMLInputElement} element - input element that will be a typeahead
 */
function markAsRequired(element) {
	const isRequired = element.required
	if (isRequired) {
		const parent = element.closest('.form-floating')
		parent.classList.add('required-typeahead')
	}
}
