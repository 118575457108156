/**
 * SelectUs
 *
 * _Changelog_
 * 1.3.1
 *	- `attributeChangedCallback` only does something once the `this.initialized` is `true`. The old behaviour created crashes, because certain elements might not have been initialized yet.
 * 1.3
 * 	- Added a changelog
 * 	- __SelectUs rendering performance drastically increased__
 * 	- Removed the inheritance between SelectUs and SelectUsRemote
 * 		- This adds _some_ duplication but enabled the performance optimization
 * 		  without a massive rewrite of the SelectUsRemote component. I'm fine with that.
 * 		- It also simplifies both classes.
 * 	- Implements connection callbacks.
 * 		- This allows SelectUs to be move inside the DOM without duplicating the integrated elements.
 */
import { escapeHtml } from '../utils/html'
import { gettext } from '../utils/translation.js'
import { get, getByUrl } from '../api/crud.js'
import { abortAbortController } from '../utils/helpers/apiHelpers.js'
import { delay } from '../utils/delays.js'
import { Dropdown } from 'bootstrap'
import { debounce } from '../utils/debounce'
import { setLoadingCircle } from '../utils/loader.mjs'
import {
	addTooltipsToElement,
	disposeTooltipsFromElement,
} from '../utils/bootstrap/tooltip'

/**
 * @typedef {Map<string, DocumentFragment>} OptionGrouping
 */

/* eslint-disable jsdoc/valid-types */
/**
 * Selectus Webcomponent
 *
 * This component is the replacement for chosen.js. It does work a bit differently, but using it as a developer is simple.
 *
 * It wraps around a normal `<select>`:
 * ```html
 * <select-us>
 *   <select>
 *     <option value="01">Item #1</option>
 *     <optgroup label="Group 1">
 *        <option value="11">Item #1.1</option>
 *      </optgroup>
 *   </select>
 * </select-us>
 * ```
 * It supports both single select and multi-select (`<select multiple>`).
 *
 * `<optgroup>` is supported on a single level, that means no nested optgroups. This is inline with the HTML standard.
 *
 * Since this component is used by just us, I've built it directly with Bootstrap 5 classes and without using the ShadowDOM.
 *
 *
 * Custom attributes:
 *
 * @param {any} `small` - will render the component smaller but ommits the `text-label` (floating label). Best used in other components where the context replaces the need for the label.
 * @param {any} `required` - will render a `*` after the label and change the `fa-icon` to green, if used. Useless whenn used with `small`.
 * @param {any} `allow-empty - in single-selects this option allows for deselecting. This goes against how normal selects work. Ignored in mutli-select.
 * @param {string} `text-no-match-selections` - shown if nothing is selected or the search matches none of the selected options
 * @param {string} `text-no-match-options` - shown if no options are left to select or the serach macthes none of the available options
 * @param {string} `text-label` - floating label text of the visible input. Ignored if `small` is set.
 * @param {string} `text-select-button` - label of the select button that opens the dropdown.
 * @param {string} `text-search-label` - floating label of the search input in the dropdown.
 * @param {string} `fa-icon` - Font Awesome icon class string.
 */
export class SelectUs extends HTMLElement {
	constructor() {
		super()
		this.initialized = false
		this.select =
			this.querySelector('select') ||
			this.appendChild(document.createElement('select'))
		this.multiple = this.select.multiple
		this.isSmall = this.getAttribute('small') != null // This is used for stuff like the querybuilder. No (floating) label!
		this.isRequired = this.getAttribute('required') != null
		this.allowEmpty = this.getAttribute('allow-empty') != null

		this.faIcon = this.getAttribute('fa-icon')

		this._id = this.getAttribute('id') || this.select.getAttribute('id') || ''

		this.noMatchingSelection =
			this.getAttribute('text-no-match-selections') ||
			gettext('Keine ausgewählten Elemente gefunden')
		this.noMatchingOptions =
			this.getAttribute('text-no-match-options') ||
			gettext('Keine Elemente gefunden')
		this.labelText = this.getAttribute('text-label') || gettext('Auswahl')
		this.selectButtonText =
			this.getAttribute('text-select-button') || gettext('Auswählen')
		this.searchLabelText =
			this.getAttribute('text-search-label') ||
			gettext('Tippen zum Durchsuchen')

		this.select.style.display = 'none' // Hides the underlying <select>
		this.disabled = this.select.disabled
		this.setAttribute('disabled', this.disabled)
	}

	/**
	 * Getter that returns an array of selected <option> elements
	 *
	 * @returns {Array<HTMLOptionElement>} list of selected <option> elements
	 */
	get selected() {
		return Array.from(this.select.selectedOptions)
	}

	/**
	 * Getter that returns the values of the selected elements in an array.
	 *
	 * @returns {Array<string>} array of values of selected options
	 */
	get selectedValues() {
		return this.selected.map((option) => option.value)
	}

	/**
	 * Getter that returns the labels of the selected elements in an array.
	 *
	 * @returns {Array<string>} array of labels of selected options
	 */
	get selectedLabels() {
		return this.selected.map((option) => trimText(option.innerText))
	}

	/**
	 * Get the element within a selected element given by "selector" that has a given dataset value attribute set
	 * This is implemented in a way that chararacters that would make the attribute selector invalid can still be used as values
	 *
	 * @param {string} selector - The selector for the outer element (can be an empty string if there is no outer element to search within)
	 * @param {string} dataValue - The value to search the element for
	 * @returns {object} - The corresponding element
	 */
	getElementWithDataValue(selector, dataValue) {
		let optionListElement
		try {
			optionListElement = this.querySelector(
				`${selector} [data-value="${dataValue}"]`
			)
		} catch (error) {
			optionListElement = Array.from(
				this.querySelectorAll(`${selector} [data-value]`)
			).filter((option) => {
				return option.dataset.value === dataValue
			})[0]
		}
		return optionListElement
	}

	/**
	 * Selects the options with the given values, if possible.
	 *
	 * Keep in mind, that multiple values only work correctly for multi-selects.
	 *
	 * @param {string | Array<string>} values - <option> values to select
	 * @param {boolean} dispatchEvent - if this is true [default] it will dispatch a `selected` event for each option selected, else for none
	 * @param {boolean} createMissingOptions -- create options that should be selected but don't exist
	 */
	setSelection(values, dispatchEvent = true, createMissingOptions = false) {
		if (!values || values.length === 0) return
		if (!Array.isArray(values)) values = [values]
		if (!this.multiple && values.length > 1) {
			console.warn(
				'Selecting multiple values in a SelectUs that only allows single select is unsupported and results in undefined behaviour, please adjust.'
			)
		}
		this.querySelectorAll('.deletedOption').forEach((el) => el.remove())
		let element
		for (let i = 0; i < values.length; i++) {
			const value = values[i]
			// If the value is an object, it consists of value and label
			const actualValue = value.value ? value.value : value
			const actualText = value.label ? value.label : value
			element = this.getElementWithDataValue('', actualValue)
			if (element) {
				this._select(element, dispatchEvent)
			} else if (createMissingOptions) {
				// If the user has a value saved for this field that is not among the available options anymore, add it, select it but disable it
				const missingOption = `<option value="${actualValue}" class="deletedOption" disabled data-toggle="tooltip" title="${gettext(
					'Diese Option wurde gesetzt, steht aber mittlerweile nicht mehr zur Auswahl zur Verfügung und kann deshalb nicht wieder gesetzt werden.'
				)}">${actualText}</option>`
				const div = document.createElement('div')
				div.innerHTML = missingOption.trim()
				const missingOptionElement = div.firstChild
				this.select.options.add(missingOptionElement)
				this.update()
				element = this.getElementWithDataValue('', actualValue)
				this._select(element, dispatchEvent)
			}
		}
	}

	/**
	 * Unselects all selected options.
	 */
	clear() {
		this.select.selectedIndex = -1
		this._showAllOptions()
		this._createSelectionList()
		this._updateSelectionInput()
		this._setSelectButtonState()
	}

	/**
	 * Selects the first option.
	 */
	selectFirstOption() {
		this.select.selectedIndex = 0
		this._showAllOptions()
		this._createSelectionList()
		this._updateSelectionInput()
		this._setSelectButtonState()
	}

	/**
	 * Reload the options and render the select-us anew.
	 *
	 * __This action can be inperformant for large selects. Be sure to only use when really necessary!__
	 */
	update() {
		this.querySelector('.select-us__search').value = ''
		const optionsWrapper = this.querySelector('.select-us__options')
		optionsWrapper.replaceChildren(this._createOptionsList())
		this._searchOptions()
		this._createSelectionList()
		this._updateSelectionInput()
		this._setSelectButtonState()
	}

	static get observedAttributes() {
		return ['disabled']
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (!this.initialized) {
			return
		}
		if (name === 'disabled') {
			const isDisabled = newValue.toLocaleLowerCase() === 'true'
			this.select.disabled = isDisabled
			this.disabled = isDisabled
			this.update()
		}
	}

	connectedCallback() {
		createScaffolding(this)
		this.selectionInput = this.querySelector('.select-us__selection-input')
		this._createDropdownReference()
		this.initialized = true
		this.update()
	}

	disconnectedCallback() {
		for (const element of this.children) {
			if (element !== this.select) {
				element.remove()
			}
		}
		this.initialized = false
	}

	// --------------------------------------------------------------
	// DOM related
	// --------------------------------------------------------------

	/**
	 * Creates the list of all options once.
	 *
	 * @private
	 * @returns {DocumentFragment} representation of the <option> and <optgroup> as Bootstrap dropdown elements.
	 */
	_createOptionsList() {
		this.options = this.select.options
		this.optionsByValue = {}
		this.optionIndexByValue = {}

		/**
		 * @type {OptionGrouping}
		 */
		const options = new Map()
		for (let i = 0; i < this.options.length; i++) {
			const option = this.options[i]
			this.optionsByValue[option.value] = option
			this.optionIndexByValue[option.value] = i

			const group = findGroupName(option)
			const grouping = options
			if (!Object.hasOwnProperty.call(grouping, group)) {
				const groupFragment = document.createDocumentFragment()
				groupFragment.appendChild(createGroupHeader(group))
				if (!grouping.has(group)) {
					grouping.set(group, groupFragment)
				}
			}
			const element = createElement(
				option.value,
				trimText(option.dataset.orginalname || option.innerHTML),
				false,
				group,
				option.dataset.short,
				option.disabled,
				option.classList.contains('deletedOption')
			)
			if (option.selected) hide(element)
			grouping.get(group).appendChild(element)
		}
		const optionsHtml = this._flattenObject(options)
		const noMatching = createNoMatchElement(this.noMatchingOptions)
		hide(noMatching)
		optionsHtml.append(noMatching)
		return optionsHtml
	}

	/**
	 * Dynamically creates the list of selected elements.
	 *
	 * @param {string | undefined} searchTerm term filter the list by
	 */
	_createSelectionList(searchTerm) {
		const selectionWrapper = this.querySelector('.select-us__selected')
		/**
		 * @type {OptionGrouping}
		 */
		const selections = new Map()
		for (const option of this.select.selectedOptions) {
			const group = findGroupName(option)
			if (!Object.hasOwnProperty.call(selections, group)) {
				const groupFragment = document.createDocumentFragment()
				groupFragment.appendChild(createGroupHeader(group))
				if (!selections.has(group)) {
					selections.set(group, groupFragment)
				}
			}
			const element = createElement(
				option.value,
				trimText(option.dataset.orginalname || option.innerHTML),
				option.selected,
				group,
				option.dataset.short,
				option.disabled
			)
			if (
				searchTerm === undefined ||
				searchTerm === '' ||
				matchesSearchTerm(option, group, searchTerm)
			) {
				selections.get(group).appendChild(element)
			}
		}
		let selectionsHtml = this._flattenObject(selections)
		if (selectionsHtml.childElementCount === 0) {
			selectionsHtml = createNoMatchElement(this.noMatchingSelection)
		}
		selectionWrapper.replaceChildren(selectionsHtml)
	}

	/**
	 * Takes an object with groups as keys and arrays of template strings as values and builds one large template string
	 *
	 * @param {OptionGrouping} instance - maps group names (and the `undefined` no group) to `DocumentFragment`s
	 * @returns {DocumentFragment} combined `DocumentFragment`
	 */
	_flattenObject(instance) {
		const element = document.createDocumentFragment()
		for (const fragment of instance.values()) {
			// If childElementCount == 1, we only have the Group header, which we don't want to render
			if (fragment.childElementCount > 1) {
				element.appendChild(fragment)
			}
		}
		return element
	}

	/**
	 * Updates the outer input to display the labels of all selected options
	 */
	_updateSelectionInput() {
		this.selectionInput.value = this.selectedLabels?.join(', ')
	}

	/**
	 * Sets the state of the select button, used changing `disabled` state.
	 */
	_setSelectButtonState() {
		this.querySelector('.select-us__selectButton').disabled = this.disabled
	}

	/**
	 * Creates a Bootstrap dropdown object
	 */
	_createDropdownReference() {
		this.dropdownElement = this.querySelector('.dropdown-toggle')
		this.dropdown = Dropdown.getOrCreateInstance(this.dropdownElement)
	}

	/**
	 * Shows all options.
	 */
	_showAllOptions() {
		for (const element of this.querySelectorAll('.select-us__options button')) {
			element.classList.remove('d-none', 'visually-hidden', 'active')
		}
	}

	/**
	 * Searches the options list and hides all elements not matching.
	 *
	 * @param {string | undefined } term - term to search by
	 */
	_searchOptions(term) {
		const groupCount = {}
		let hiddenCount = 0
		for (const element of this.querySelectorAll('.select-us__options button')) {
			if (!Object.hasOwnProperty.call(groupCount, element.dataset.group)) {
				groupCount[element.dataset.group] = { hidden: 0, total: 0 }
			}
			groupCount[element.dataset.group].total++
			const matchesTerm =
				caseInsensitiveMatch(element.dataset.value, term) ||
				caseInsensitiveMatch(element.dataset.group, term) ||
				caseInsensitiveMatch(element.dataset.short, term) || // This is for groups only
				caseInsensitiveMatch(trimText(element.textContent), term)
			const isSelected =
				this.options[this.optionIndexByValue[element.dataset.value]].selected
			const termIsEmpty = term === '' || term === null
			if (!isSelected && (matchesTerm || termIsEmpty)) {
				show(element)
			} else {
				hide(element)
				groupCount[element.dataset.group].hidden++
				hiddenCount++
			}
		}

		// Hide the optgroup headings if all elements of that group are hidden.
		for (const element of this.querySelectorAll(
			'.select-us__options .dropdown-header'
		)) {
			const group = element.textContent
			if (group === 'undefined') continue
			const counts = groupCount[group]
			if (counts.hidden === counts.total) {
				hide(element)
			} else {
				show(element)
			}
		}
		// Show the "no matches" element if _all_ elements of the options list are hidden
		const noMatching = this.querySelector('.select-us__options .no-matching')
		if (hiddenCount === this.options.length) {
			show(noMatching)
		} else {
			hide(noMatching)
		}
	}

	// --------------------------------------------------------------
	// Event listener callbacks
	// --------------------------------------------------------------

	/**
	 * Callback to handle change events for search input
	 *
	 * @param {event} event - change event instance
	 */
	_handleSearch(event) {
		const term = event?.target?.value
		if (typeof term !== 'string') {
			return
		}
		this._searchOptions(term)
		this._createSelectionList(term)
	}

	/**
	 * Handle element selection
	 *
	 * @param {HTMLElement} element - HTML element that was clicked
	 * @param {boolean} [dispatchEvent=true] - Dispatch the "selected" event if true
	 */
	_select(element, dispatchEvent = true) {
		const elementSelected = element.classList.contains('active')
		const optionListElement = this.getElementWithDataValue(
			'.select-us__options',
			element.dataset.value
		)
		const search = this.querySelector('.select-us__search').value
		if (!this.multiple) {
			if (elementSelected) {
				if (this.allowEmpty) {
					// We don't call `clear()` here, because we rerender anyway.
					this.select.selectedIndex = -1
				} else {
					return
				}
			}
			for (let i = 0; i < this.options.length; i++) {
				this.options[i].selected = false
				this.optionsByValue[this.options[i].value] = this.options[i]
			}
		}
		this.optionsByValue[element.dataset.value].selected = !elementSelected

		hide(optionListElement)
		this._searchOptions(search)
		this._createSelectionList(search, dispatchEvent)
		this._updateSelectionInput()

		if (!this.multiple) {
			this.dropdown.hide()
		}
		if (dispatchEvent) {
			this.dispatchEvent(new Event('selected'))
		}
	}
}

/* eslint-disable jsdoc/valid-types */
/**
 * SelectUsRemote Webcomponent
 *
 * This component extends the SelectUs component by loading the data from the API.
 *
 * Generally similiar to the normal SelectUs. You don't need to wrap a `<select>`.
 *
 * ! An ID is very important! If two select-us-remote on the same page share an ID one will stop the requests of the other!
 *
 * If you need to change how the label is rendered use the `setRenderer()` function.
 *
 * `<optgroup>` are _not_ supported!
 *
 *
 * Custom attributes:
 *
 * @param {any} `small` - will render the component smaller but ommits the `text-label` (floating label). Best used in other components where the context replaces the need for the label.
 * @param {any} `required` - will render a `*` after the label and change the `fa-icon` to green, if used. Useless whenn used with `small`.
 * @param {string} `text-no-match-selections` - shown if nothing is selected or the search matches none of the selected options
 * @param {string} `text-no-match-options` - shown if no options are left to select or the serach macthes none of the available options
 * @param {string} `text-label` - floating label text of the visible input. Ignored if `small` is set.
 * @param {string} `text-select-button` - label of the select button that opens the dropdown.
 * @param {string} `text-search-label` - floating label of the search input in the dropdown.
 * @param {string} `fa-icon` - Font Awesome icon class string.
 * @param {string} `endpoint` - API endpoint. Required.
 * @param {string} `value-field` - which field of the result object data should be used for the `<option>` value. Usually `id` or `pk`.
 * @param {string} `label-field` - which field of the result object data should be used for the `<option>` `innerHTML`.
 * @param {string} `fields` - which fields to load from the API. Sets the `query=` URL parameter.
 * @param {string} `filter` - defines the filter for requests of unselected options. Format: `'filterKey1=filterValue1,filterKey2=filterValue2'`
 * @param {string} `selection-filter` - defines the filter for the request of selected options. Format: `'filterKey1=filterValue1,filterKey2=filterValue2'`
 * @param {string} `fetch-delay` - `default`, `short`, `long`, `none`. `default` will be used if the attribute is missing.
 * @param {any}    `allow-none` - if set allows for a 'None' option to appear. If given a value that value is the shown text.
 */
export class SelectUsRemote extends HTMLElement {
	constructor() {
		super()
		this.initialized = false
		this.select =
			this.querySelector('select') ||
			this.appendChild(document.createElement('select'))
		this.multiple = this.select.multiple
		this.isSmall = this.getAttribute('small') != null // This is used for stuff like the querybuilder. No (floating) label!
		this.isRequired = this.getAttribute('required') != null
		if (this.getAttribute('allow-empty') != null) {
			console.warn(
				'`allow-empty` is not available for remote select-us. Use the `allow-none` attribute.'
			)
		}

		this.faIcon = this.getAttribute('fa-icon')

		this._id = this.getAttribute('id') || this.select.getAttribute('id') || ''

		this.noMatchingSelection =
			this.getAttribute('text-no-match-selections') ||
			gettext('Keine ausgewählten Elemente gefunden')
		this.noMatchingOptions =
			this.getAttribute('text-no-match-options') ||
			gettext('Keine Elemente gefunden')
		this.labelText = this.getAttribute('text-label') || gettext('Auswahl')
		this.selectButtonText =
			this.getAttribute('text-select-button') || gettext('Auswählen')
		this.searchLabelText =
			this.getAttribute('text-search-label') ||
			gettext('Tippen zum Durchsuchen')

		this.endpoint = this.getAttribute('endpoint')
		this.valueField = this.getAttribute('value-field')
		this.labelField = this.getAttribute('label-field')

		this.allowNoSelection = this.getAttribute('allow-none') != null
		this.textEmptySelection =
			this.getAttribute('allow-none') || gettext('Keine Auswahl')
		// Using the ID of the SelectUs to prevent clashes
		this.noneValue = `${this._id}-none`
		this.requestSignalId = `select-us-remote-get_${this._id}`
		this.timeoutDelayMS =
			delay[this.getAttribute('fetch-delay')] || delay.default

		if (!this.endpoint) console.error('endpoint attribute is required.')
		if (!this.valueField) console.error('value-field attribute is required')
		if (!this.labelField) console.error('label-field attribute is required')

		this.fields = this.getAttribute('fields') || ''
		this.filter = this._buildFilterObject(this.getAttribute('filter'))
		this.selectionFilter = this._buildFilterObject(
			this.getAttribute('selection-filter')
		)
		this._resetAPI()

		this._updateOptions()

		this.select.style.display = 'none' // Hides the underlying <select>
		this.disabled = this.select.disabled
		this.setAttribute('disabled', this.disabled)
	}

	/**
	 * Getter that returns an array of selected <option> elements
	 *
	 * @returns {Array<HTMLOptionElement>} list of selected <option> elements
	 */
	get selected() {
		return Array.from(this.select.selectedOptions)
	}

	/**
	 * Getter that returns the values of the selected elements in an array.
	 *
	 * @returns {Array<string>} array of values of selected options
	 */
	get selectedValues() {
		return this.selected.map((option) => option.value)
	}

	/**
	 * Getter that returns the labels of the selected elements in an array.
	 *
	 * @returns {Array<string>} array of labels of selected options
	 */
	get selectedLabels() {
		return this.selected.map((option) => trimText(option.innerText))
	}

	/**
	 * Selects the options with the given values, if possible.
	 *
	 * Keep in mind, that multiple values only work correctly for multi-selects.
	 *
	 * @param {string | Array<string>} values - <option> values to select
	 * @param {boolean} dispatchEvent - if this is true [default] it will dispatch a `selected` event for each option selected, else for none
	 */
	setSelection(values, dispatchEvent = true) {
		if (!values || values.length === 0) return
		if (!Array.isArray(values)) values = [values]
		if (!this.multiple && values.length > 1) {
			console.warn(
				'Selecting multiple values in a SelectUs that only allows single select is unsupported and results in undefined behaviour, please adjust.'
			)
		}
		const elements = values
			.map((value) => this.querySelector(`[data-value="${value}"]`))
			.filter((element) => element)
		elements.forEach((element) => this._select(element, dispatchEvent))
	}

	/**
	 * Unselects all selected options.
	 */
	clear() {
		this.select.selectedIndex = -1
		this.update()
	}

	/**
	 * Selects the first option.
	 */
	selectFirstOption() {
		this.select.selectedIndex = 0
		this.update()
	}

	/**
	 * Reload the options and render the select-us anew.
	 */
	update() {
		this._updateOptions()
		this._updateRender()
	}

	static get observedAttributes() {
		return ['disabled', 'filter']
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (!this.initialized) {
			return
		}
		switch (name) {
			case 'disabled': {
				const isDisabled = newValue.toLocaleLowerCase() === 'true'
				this.select.disabled = isDisabled
				this.disabled = isDisabled
				this._updateRender()
				break
			}
			case 'filter': {
				if (!oldValue) {
					return
				}
				this.filter = this._buildFilterObject(newValue)
				this.reload()
				break
			}
		}
	}

	connectedCallback() {
		this._setup()
	}

	disconnectedCallback() {
		for (const element of this.children) {
			if (element !== this.select) {
				element.remove()
			}
		}
		this.initialized = false
	}

	/**
	 * Checks if the none/empty value is selected.
	 *
	 * @returns {boolean} true if the empty value (==this.noneValue)
	 */
	get isEmptyValueSelected() {
		return this.selectedValues.includes(this.noneValue)
	}

	// --------------------------------------------------------------
	// DOM related
	// --------------------------------------------------------------

	_setup() {
		if (Object.keys(this.selectionFilter).length > 0) {
			// Get selected data
			get(this.endpoint, this.fields, this.selectionFilter, 1000).then(
				(data) => {
					this._setOptions(data, true, true)
					this._updateOptions()
					this._setupSuper()
				}
			)
		} else {
			this._updateOptions()
			this._setupSuper()
		}
	}

	/**
	 * Helper that builds the element.
	 *
	 * This helper is needed to enable the inherited remote version
	 */
	_setupSuper() {
		createScaffolding(this)
		this._getOptions()
		this.selectionInput = this.querySelector('.select-us__selection-input')
		this._updateSelectionInput()
		this._createDropdownReference()
		this.initialized = true
	}

	/**
	 * Gets the remote data and buiolds the `select` from the results.
	 *
	 * @param {string?} searchTerm - term to search the API
	 * @param {boolean} dispatchEvent - if true [default] dispatch the change event
	 */
	_getOptions(searchTerm, dispatchEvent = true) {
		setLoadingCircle(
			this.querySelector('.select-us__options'),
			true,
			true,
			true
		)
		this._getData(searchTerm)
			.then((data) => {
				this.querySelector('.loading-circle').remove()
				if (data.status !== 200) return

				this._setOptions(data, !this._shouldGetNextPage(searchTerm))
				this._updateOptions()
				this._fillOptions(searchTerm, dispatchEvent)
				this.next = data.data.next
				this.lastSearch = searchTerm
				this._updateSelectionInput()
			})
			.catch((error) => {
				if (error.name === 'AbortError') return
				console.error(error)
			})
	}

	/**
	 * Filles the selected and options list and applys the search filter
	 *
	 * @param {string?} searchTerm - term that filters selected and options list
	 * @param {boolean} dispatchEvent - if true [default] dispatch the change event
	 */
	_fillOptions(searchTerm, dispatchEvent = true) {
		const selectionWrapper = this.querySelector('.select-us__selected')
		const optionsWrapper = this.querySelector('.select-us__options')
		selectionWrapper.innerHTML = ''
		optionsWrapper.innerHTML = ''

		const selections = {}
		const options = {}

		this.options.forEach((option) => {
			const group = findGroupName(option)
			const grouping = option.selected ? selections : options
			if (!Object.hasOwnProperty.call(grouping, group)) {
				grouping[group] = [createGroupHeader(group)]
			}
			const element = createElement(
				option.value,
				trimText(option.textContent),
				option.selected,
				group,
				option.dataset.short,
				option.disabled
			)
			if (
				searchTerm === undefined ||
				matchesSearchTerm(option, group, searchTerm)
			) {
				grouping[group].push(element)
			}
		})
		const selectionsHtml =
			this._flattenObject(selections) ||
			createNoMatchElement(this.noMatchingSelection)
		const optionsHtml =
			this._flattenObject(options) ||
			createNoMatchElement(this.noMatchingOptions)
		selectionWrapper.appendChild(selectionsHtml)
		optionsWrapper.appendChild(optionsHtml)
		if (dispatchEvent) {
			this.dispatchEvent(new Event('change'))
		}
	}

	/**
	 * Takes an object with groups as keys and arrays of template strings as values and builds one large template string
	 *
	 * @param {object} instance - maps group names (and the `undefined` no group) to arrays of template strings
	 * @returns {string} combined template string
	 */
	_flattenObject(instance) {
		const html = new DocumentFragment()
		Object.values(instance).forEach((list) => {
			// If length == 1, we only have the Group header, which we don't want to render
			if (list.length > 1) {
				html.append(...list)
			}
		})
		return html
	}

	/**
	 * Updates `options` and `optionsByValue`. Follow it by `updateRender()` to show the changes.
	 *
	 * - `this.options`: Array of `HTMLOptionElements` from the underlying `<select>`
	 * - `this.optionsByValue`: Maps the value of each option to its `HTMLOptionElement`
	 */
	_updateOptions() {
		this.options = Array.from(this.select.options)
		this.optionsByValue = {}
		this.options.forEach((option) => {
			this.optionsByValue[option.value] = option
		})
	}

	/**
	 * Update the dropdown, reset the search and updates the outer input. Only needed after changes to the underlying `<select>` and after a call to `updateOptions()`.
	 */
	_updateRender() {
		this.querySelector('.select-us__search').value = ''
		this._getOptions()
		this._updateSelectionInput()
		this._setSelectButtonState()
	}

	/**
	 * Updates the outer input to display the labels of all selected options
	 */
	_updateSelectionInput() {
		this.selectionInput.value = this.selectedLabels?.join(', ')
	}

	/**
	 * Sets the state of the select button, used changing `disabled` state.
	 */
	_setSelectButtonState() {
		this.querySelector('.select-us__selectButton').disabled = this.disabled
	}

	/**
	 * Creates a Bootstrap dropdown object
	 */
	_createDropdownReference() {
		this.dropdownElement = this.querySelector('.dropdown-toggle')
		this.dropdown = Dropdown.getOrCreateInstance(this.dropdownElement)
	}

	/**
	 * Fetches data from the API
	 *
	 * @param {string} searchTerm - term to search the API
	 * @returns {Promise<object>} data from the API
	 */
	_getData(searchTerm) {
		if (this._shouldGetNextPage(searchTerm)) {
			return getByUrl(this.next)
		}

		return this._fetchEndpoint(searchTerm)
	}

	/**
	 * Creates `option` elements for the API data
	 *
	 * @param {object} data - API data
	 * @param {boolean} clearOptions - if true remove all not selected options from the select
	 * @param {boolean} isSelected - if true mark all options as selected; only used in `_setup()`
	 */
	_setOptions(data, clearOptions, isSelected) {
		const results = data.data.results
		if (clearOptions) {
			this._clearOptions()
		}
		if (this.allowNoSelection && !this.isEmptyValueSelected) {
			this._createEmptyOptionElement()
		}
		results
			.filter(
				(element) => !this.optionsByValue[element[this.valueField]]?.selected
			)
			.map((element) => this._createOptionElement(element, isSelected))
	}

	/**
	 * Removes all options from the select, that are not selected
	 */
	_clearOptions() {
		Array.from(this.select.options)
			.filter((option) => !option.selected)
			.map((option) => option.remove())
	}

	/**
	 * Creates a HTMLOptionElement
	 *
	 * @param {object} data - data of one object instance from the API data
	 * @param {boolean} isSelected - if ture mark the option as selected
	 */
	_createOptionElement(data, isSelected = false) {
		const option = document.createElement('option')
		option.value = data[this.valueField]
		option.innerHTML = this._renderer(data)
		option.selected = isSelected
		this.select.options.add(option)
	}

	/**
	 * Create a HTMLOptionElement for the optional `allow-none` Option
	 */
	_createEmptyOptionElement() {
		const option = document.createElement('option')
		option.value = this.noneValue
		option.innerHTML = this.textEmptySelection
		option.selected =
			this.selected.length === 0 || this.selectedValues.includes(option.value)
		this.select.options.add(option)
	}

	// --------------------------------------------------------------
	// Event listener callbacks
	// --------------------------------------------------------------

	/**
	 * Callback to handle change events for search input
	 *
	 * @param {event} event - change event instance
	 */
	_handleSearch(event) {
		const term = event?.target?.value
		if (typeof term !== 'string') {
			return
		}
		this._resetAPI()
		debounce(() => {
			this._getOptions(term)
		}, delay.default)()
	}

	_select(element, dispatchEvent = true) {
		const elementSelected = element.classList.contains('active')
		if (!this.multiple) {
			if (elementSelected) {
				if (this.allowEmpty) {
					this.optionsByValue[this.noneValue].selected = true
				} else {
					return
				}
			}
			this.options.forEach((option) => {
				option.selected = false
			})
			this.dropdown.hide()
		}
		this.optionsByValue[element.dataset.value].selected = !elementSelected
		if (dispatchEvent) {
			this.dispatchEvent(new Event('selected'))
		}
		const search = this.querySelector('.select-us__search').value
		this._getOptions(search, dispatchEvent)
		this._updateSelectionInput()
	}

	// --------------------------------------------------------------
	// API request related
	// --------------------------------------------------------------
	/**
	 * Helper that mimicks the `get` of the API, but provides search
	 *
	 * @param {string} searchTerm - term to search the API
	 * @returns {Promise<object>} data from the API
	 */
	_fetchEndpoint(searchTerm = '') {
		const filters = searchTerm
			? { ...this.filter, search: searchTerm }
			: this.filter
		return get(
			this.endpoint,
			this.fields,
			filters,
			25,
			1,
			'',
			true,
			{},
			this.requestSignalId
		)
	}

	/**
	 * Stops previous requests and resets the `this.next` page URL
	 */
	_resetAPI() {
		// This abort should not be needed—as the API request should kill it anyway—but to make sure
		abortAbortController(this.requestSignalId)
		this.next = undefined
	}

	/**
	 * Maps the pairs from the string into an object.
	 *
	 * @param {string} filterString - filter extracted from the attributes. Comma-seperated list of `key=value` pairs
	 * @returns {object} mapped the key-value pairs to object keys and values; or empty object
	 */
	_buildFilterObject(filterString) {
		if (!filterString) return {}
		return filterString
			.toString()
			.split(',')
			.map((filter) => filter.split('='))
			.reduce((result, current) => {
				let value = result[current[0]]
				if (value === undefined) {
					// Normal case: Just use the current value
					value = current[1]
				} else if (!Array.isArray(value)) {
					// A previous value exists, but it is not an array yet ⇒ make one
					value = [value, current[1]]
				} else {
					// A previous value exists and is an array ⇒ append
					value = [...value, current[1]]
				}
				return Object.assign(result, { [current[0]]: value })
			}, {})
	}

	// --------------------------------------------------------------
	// Helpers
	// --------------------------------------------------------------

	/**
	 * Checks if the next URL should be used or if a new page has to be requested.
	 *
	 * @param {string} searchTerm - term to search the API
	 * @returns {boolean} - if true, use the `next` URL, else fetch new data
	 */
	_shouldGetNextPage(searchTerm) {
		const hasNextPage = this.next !== undefined && this.next != null
		return hasNextPage && searchTerm === this.lastSearch
	}

	/**
	 * Generates the label for an option
	 *
	 * @param {object} data - data of one object instance from the API data
	 * @returns {string} label to render as the `innerText` of the option
	 */
	_renderer(data) {
		const label = this.labelField
			.split('__')
			.reduce((obj, cur) => obj[cur], data)
		return `${label || gettext('-- Ungültiger Wert --')}`
	}

	/**
	 *
	 * @callback RendererFunctionCallback
	 * @param {object} data - Data of one instance from the `this.endpoint`.
	 * @returns {string} string to display in `.innerHTML` of an option tag.
	 */
	/**
	 * Sets the render function that defines the value for the `.innerHTML` of the option element.
	 *
	 * This will reload the options.
	 *
	 * @param {RendererFunctionCallback} renderFunction - Render function that takes a data object of one instance from the `this.endpoint` and returns a string that will be place in the `innerText` of an option tag. Keep in mind to handle translation.
	 */
	setRenderer(renderFunction) {
		this._renderer = renderFunction
		this.reload()
	}

	/**
	 * Reloads the data from the API starting at the first page.
	 */
	reload() {
		this._resetAPI()
		this._getOptions()
	}
}

customElements.define('select-us', SelectUs)
customElements.define('select-us-remote', SelectUsRemote)

// --------------------------------------------------------------
// #region Utility functions
// --------------------------------------------------------------

/**
 * Searches through option.value, option.innerText and group
 *
 * @param {HTMLOptionElement} option - <option> to check
 * @param {string?} group - name of the group
 * @param {string} search - search term to match
 * @returns {boolean} true, if search is in option.value or option.innerText or in the group name
 */
function matchesSearchTerm(option, group, search) {
	return (
		caseInsensitiveMatch(trimText(option.innerText), search) ||
		caseInsensitiveMatch(option.value, search) ||
		caseInsensitiveMatch(group, search)
	)
}

/**
 * Case insensitive test if term is in text.
 *
 * @param {string} text - text to search
 * @param {string} term - term to find
 * @returns {boolean} true if term is in text
 */
function caseInsensitiveMatch(text, term) {
	if (!text) {
		return false
	}
	if (!term) {
		return true
	}
	return text.toLowerCase().includes(term.toLowerCase())
}
/**
 * Filters out newlines, CR and tabs from the text. And multiple spaces are replaced with a single one.
 * This is needed because option.innerText does retrieve them and we can't enforce everyone to not use linebreaks in option tags.
 *
 * @param {string} text - option.innerText
 * @returns {string} text without `\n`, `\r` or `\t`
 */
function trimText(text) {
	return text.replaceAll(/[\r\n\t]+/gm, '').replaceAll(/\s{2,}/gm, ' ')
}

// --------------------------------------------------------------
// #endregion Utility functions
// --------------------------------------------------------------

// --------------------------------------------------------------
// #region DOM related
// --------------------------------------------------------------

/**
 * Create the shared Select-Us DOM elements.
 *
 * @param {SelectUs | SelectUsRemote} instance - the class
 */
function createScaffolding(instance) {
	const labelText = `${
		instance.faIcon
			? `<i class="${instance.faIcon}${
					instance.isRequired ? ' text-primary' : ''
			  }"></i>&nbsp`
			: ''
	}${instance.labelText}`
	const html = [
		`<div class="select-us input-group ${
			instance.isSmall ? 'input-group-sm' : ''
		}">`,
		`<div class="${instance.isSmall ? '' : 'form-floating'} flex-grow-1">`,
		`<input type="text" class="select-us__selection-input form-control ${
			instance.isSmall ? 'form-control-sm' : ''
		}" aria-label="${instance.labelText}" placeholder="${
			instance.labelText
		}" readonly ${instance.isRequired ? 'required' : ''}>`,
		instance.isSmall
			? ''
			: `<label for="select-us__selection-input">${labelText}</label>`,
		'</div>',
		`<button class="btn btn-outline-primary dropdown-toggle align-self-stretch select-us__selectButton" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">${instance.selectButtonText}</button>`,

		// Dropdown
		'<div class="dropdown-menu dropdown-menu-end overflow-auto w-100 shadow-lg">',
		'<div class="px-2 py-2">',
		'<div class="form-floating">',
		`<input type="text" class="form-control select-us__search" name="select-us__search" placeholder="${instance.searchLabelText}">`,
		`<label for="select-us__search">${instance.searchLabelText}</label>`,
		'</div>',
		'</div>',
		instance.multiple
			? `<li><a class="dropdown-item disabled">${gettext(
					'Mehrfachauswahl möglich'
			  )}</a></li>`
			: '',
		'<div class="dropdown-divider"></div>',
		'<div class="select-us__selected"></div>',
		'<div class="dropdown-divider"></div>',
		'<div class="select-us__options"></div>',
		'</div>',
		'</div>',
	]

	instance.insertAdjacentHTML('beforeend', html.join('\n'))
	instance.querySelector('.dropdown-menu').style.maxHeight =
		'clamp(20rem, 25vh, 40vh)'

	instance
		.querySelector('.select-us__search')
		.addEventListener('input', (e) => instance._handleSearch(e))
	instance
		.querySelector('.select-us__search')
		.addEventListener('keydown', (event) => {
			// Selects the first option if the keystroke is Enter on the select input
			if (event.key === 'Enter') {
				instance
					.querySelector('.select-us__options button:not(.visually-hidden)')
					?.click()
			}
		})
	instance._setSelectButtonState()

	// Fix dropdown max height to not flow out of the maximum visible area
	instance
		.querySelector('.dropdown-toggle')
		.addEventListener('shown.bs.dropdown', () => {
			const viewportHeight = window.innerHeight
			const dropdown = instance.querySelector('.dropdown-menu')
			const { y: dropdownY, height: dropdownHeight } =
				dropdown.getBoundingClientRect()
			if (dropdownY + dropdownHeight >= viewportHeight) {
				dropdown.style.maxHeight = `${Math.floor(
					viewportHeight - dropdownHeight
				)}px`
			}
			instance.querySelector('.select-us__search').focus()
		})

	instance.querySelector('.dropdown-toggle').addEventListener('click', (e) => {
		// for prevent closing parent dropdown (if selectus is embededd in dropdown)
		e.preventDefault()
		e.stopPropagation()
	})

	instance.querySelector('.dropdown-menu').addEventListener('click', (e) => {
		if (e.target && e.target.classList.contains('dropdown-item')) {
			// for prevent closing parent dropdown (if selectus is embededd in dropdown)
			e.preventDefault()
			e.stopPropagation()

			instance._select(e.target)
		}
	})
}

/**
 * Returns the name of the closest optgroup, if the option is grouped.
 *
 * @param {HTMLOptionElement} option - option to find the optgroup for
 * @returns {string?} name of the optgroup or undefined
 */
function findGroupName(option) {
	return option.closest('optgroup')?.label
}

/**
 * Creates the DOM for a dropdown-header. It is only visible if name is not undefined.
 *
 * @param {string?} name - name of the group. If undefined the element is hidden
 * @returns {string} template string
 */
function createGroupHeader(name) {
	const heading = document.createElement('h6')
	heading.classList.add('dropdown-header')
	if (name === undefined) heading.classList.add('d-none')
	heading.innerText = name
	return heading
}

/**
 * Creates a dropdown-item for each option of the <select>
 *
 * @param {string} value - value of an <option> element
 * @param {string} name - name/innerText of an <option> element
 * @param {boolean} active - true if <option> is selected
 * @param {string?} group - name of the containing optgroup
 * @param {string?} short - The short of the group (only necessary for groups)
 * @param {boolean} disabled - Whether the element should be disabled (when it's not selected anymore) or not
 * @param {boolean} deletedOption - Whether the element is a deleted option
 * @returns {string} template string of the dropdown-item
 */
function createElement(
	value,
	name,
	active,
	group,
	short,
	disabled = false,
	deletedOption = false
) {
	const button = document.createElement('button')
	button.type = 'button' // Default is type="submit", which breaks selectUs inside forms.
	button.classList.add('dropdown-item')
	if (active) button.classList.add('active')
	if (disabled) button.classList.add('not-available')
	if (deletedOption) button.classList.add('deleted-option')
	button.dataset.value = value
	button.dataset.short = short || ''
	button.dataset.group = group || 'none'
	short = short ? ` (${escapeHtml(short)})` : ''
	button.innerHTML = `${escapeHtml(name)}${short}`
	return button
}

/**
 * Creates a dropdown-item for when the search doesn't match any options or when nothing is selected or left to select.
 *
 * @param {string} message message to render
 * @returns {HTMLSpanElement} dropdown-item with message
 */
function createNoMatchElement(message) {
	const element = document.createElement('span')
	element.classList.add('dropdown-item', 'disabled', 'no-matching')
	element.innerText = message
	return element
}

/**
 * Hides the given element.
 *
 * @param {HTMLElement} element HTML element to hide
 */
function hide(element) {
	element.classList.add('d-none', 'visually-hidden')
}

/**
 * Shows the given element.
 *
 * @param {HTMLElement} element HTML element to show
 */
function show(element) {
	element.classList.remove('d-none', 'visually-hidden')
	// This is used to make options a user selected that are not available anymore not selectable again
	if (element.classList.contains('not-available')) {
		element.disabled = true
	}
	if (element.classList.contains('deleted-option')) {
		element.disabled = true
		element.dataset.bsTitle = gettext(
			'Diese Option wurde gesetzt, steht aber mittlerweile nicht mehr zur Auswahl zur Verfügung und kann deshalb nicht wieder gesetzt werden.'
		)
		element.dataset.bsToggle = 'tooltip'
		addTooltipsToElement(element)
	} else {
		disposeTooltipsFromElement(element)
	}
}

// --------------------------------------------------------------
// #endregion DOM related
// --------------------------------------------------------------
