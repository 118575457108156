/**
 * This file contains all event-Helpers
 * Helpers can also be used if there is a code sequence or similar code which is often used.
 */

import { getContextData } from '../django'
import { checkVisibility } from './show'

/**
 * Wrapper for the eventListenerAdd function for the click event
 *
 * @param {object} elementsAndEvents - This dict contains all that is needed to define an event to an element:
 *                                   It should look like this: {'$id': $event, '$id2': $event2 ...}
 *                                   The keys are the ids of the elements and the values are the events
 * @returns {number}  0 for no errors and 1 if the given dict is not correct
 */
export function onclickElements(elementsAndEvents) {
	return eventListenerAdd(elementsAndEvents, 'click')
}

/**
 * Wrapper to add eventlisteners to selectors
 *
 * @param {object} elementsAndEvents - This dict contains all that is needed to define an event to an element:
 *                                   It should look like this: {'$id': $event, '$id2': $event2 ...}
 *                                   The keys are the ids of the elements and the values are the events
 * @param {string} listener - This string defines what kind of eventlistener will be added (like 'click' or 'onmouseover'...)
 * @returns {number} - 0 for no errors and 1 if the given dict is not correct
 */
export function eventListenerAdd(elementsAndEvents, listener) {
	if (!(elementsAndEvents.constructor === Object)) {
		console.error('"elementsAndEvents" is not an object') // At least print to the coder that something is wrong
		return 1
	}

	for (const [addEventElement, event] of Object.entries(elementsAndEvents)) {
		document.querySelectorAll(addEventElement).forEach((element) => {
			element.addEventListener(listener, event)
		})
	}

	return 0
}

/**
 * Trigger the click event on the previous or next page buttons
 *
 * @param {string} previousPageButtonSelector - The selector of the element (button) to click if the keystroke is right
 * @param {string} nextPageButtonSelector - The selector of the element (button) to click if the keystroke is right
 */
export function inputTriggerClick(
	previousPageButtonSelector,
	nextPageButtonSelector
) {
	document.addEventListener('keydown', (event) => {
		if (!getContextData('offset')) {
			return
		}

		if (
			!document.querySelector('.modal.show') &&
			event.target.nodeName !== 'INPUT' &&
			!event.target.classList.contains('ck-content')
		) {
			const keystroke = event.key
			switch (keystroke) {
				case 'ArrowLeft':
					document.querySelector(previousPageButtonSelector).click()
					break
				case 'ArrowRight':
					document.querySelector(nextPageButtonSelector).click()
					break
				default: // exit this handler for other keys
			}
		}
	})
}

/**
 * Extracting the dataset arguments of the event target to call the callFunction with them
 * For example:
 * '.yourElementClass': (event) => prepareFunction(event, ['datasetAttibute1', 'datasetAttibute2', ...], yourFunction),
 * This is usefull, if the callFunction is not only called by event listeners
 *
 * @param {object} event - Default JS onclick event object
 * @param {Array} dataArgs - An array containing the dataset arguments to extract
 * @param {Function} callFunction - A anonymus function called with the dataset args
 */
export function prepareFunction(event, dataArgs, callFunction) {
	event.preventDefault()
	const element = event.currentTarget
	const dataset = element.dataset
	const args = []
	dataArgs.forEach((arg) => {
		args.push(dataset[arg])
	})
	callFunction(...args)
}

/**
 * Eventlistener for when a swipe on a touchscreen happened. Directions are 'up', 'right', 'down', 'left' and 'none'
 * Use like this:
 * onSwipeDetected(window, (swipeDirection) => {
		if (swipeDirection === 'right') {
			// code
		}
	})
 *
 * @param {Element} element - DOM Element the swipe detection is bound to
 * @param {Function} callback - anonymous callback function for when a swipe happened
 * @param {boolean} freezeScreen - (Default = true) Prevents the screen from moving while the DOM element is touched/swiped
 */
export function onSwipeDetected(element, callback, freezeScreen = true) {
	const touchSurface = element
	let swipeDirection
	let startX
	let startY
	let distX
	let distY
	const threshold = 150 // required min distance traveled to be considered swipe
	const restraint = 100 // maximum distance allowed at the same time in perpendicular direction
	const allowedTime = 300 // maximum time allowed to travel that distance
	let elapsedTime
	let startTime
	const handleSwipe = callback || function (swipeDirection) {}

	touchSurface.addEventListener(
		'touchstart',
		(e) => {
			const touchObject = e.changedTouches[0]
			swipeDirection = 'none'
			startX = touchObject.pageX
			startY = touchObject.pageY
			startTime = new Date().getTime() // record time when finger first makes contact with surface
			if (freezeScreen) {
				e.preventDefault()
			}
		},
		false
	)

	touchSurface.addEventListener(
		'touchmove',
		(e) => {
			if (freezeScreen) {
				e.preventDefault()
			} // prevent scrolling when inside DIV
		},
		false
	)

	touchSurface.addEventListener(
		'touchend',
		(e) => {
			const touchObject = e.changedTouches[0]
			distX = touchObject.pageX - startX // get horizontal dist traveled by finger while in contact with surface
			distY = touchObject.pageY - startY // get vertical dist traveled by finger while in contact with surface
			elapsedTime = new Date().getTime() - startTime // get time elapsed
			if (elapsedTime <= allowedTime) {
				// first condition for awipe met
				if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
					// 2nd condition for horizontal swipe met
					swipeDirection = distX < 0 ? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
				} else if (
					Math.abs(distY) >= threshold &&
					Math.abs(distX) <= restraint
				) {
					// 2nd condition for vertical swipe met
					swipeDirection = distY < 0 ? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
				}
			}
			handleSwipe(swipeDirection)
			if (freezeScreen) {
				e.preventDefault()
			}
		},
		false
	)
}

/**
 * Adds the eventListener for toggleDisableOnInput
 *
 * @param {string} inputSelector - The selector of the input
 * @param {string} targetSelector - The selector of the target (most likely a button)
 * @param {string} eventType - The type of the event trigger default is keyup
 * @param {boolean} [fillAllInputs=true] - Check if all inputs are filled or just one
 */
export function toggleDisableOnInput(
	inputSelector,
	targetSelector,
	eventType = 'keyup',
	fillAllInputs = true
) {
	eventListenerAdd(
		{
			[inputSelector]: () => {
				executeToggleDisableOnInput(
					inputSelector,
					targetSelector,
					fillAllInputs
				)
			},
		},
		eventType
	)
}

/**
 * Toggles the disabled attribute of the target depending on if something is in the value of the input
 *
 * @param {string} inputSelector - The selector of the input
 * @param {string} targetSelector - The selector of the target (most likely a button)
 * @param {boolean} [fillAllInputs] - Check if all inputs are filled or just one
 */
export function executeToggleDisableOnInput(
	inputSelector,
	targetSelector,
	fillAllInputs = false
) {
	let disableButton = true
	for (const input of document.querySelectorAll(inputSelector)) {
		if (!input || checkVisibility(input) === undefined) {
			break
		}
		disableButton = false

		if (input && checkVisibility(input)) {
			if (fillAllInputs) {
				if (!input.value) {
					disableButton = true
					break
				}
			} else {
				if (input.value) {
					disableButton = false
					break
				}
			}
		}
	}
	document.querySelectorAll(targetSelector).forEach((button) => {
		button.disabled = disableButton
	})
}

/**
 * Simulates a click when hitting enter
 *
 * @param {Event} event - Default JS onkeydown event object
 * @param {Element} target - The target element to click when hitting enter
 */
export function clickWhenEnter(event, target) {
	if (event.key === 'Enter') {
		target.click()
	}
}
