import { getGlobals } from '../utils/django'

/**
 * Initialize tracking
 */
export async function initTracking() {
	// init matomo without using cookies on page load
	const websiteId = defineWebsiteId()
	if (websiteId !== false) {
		matomoInit(websiteId)
		matomoTagManagerInit()
	}

	window.cookieconsent?.initialise({
		palette: {
			popup: {
				background: '#efefef',
				text: '#404040',
			},
			button: {
				background: 'transparent',
				text: '#23985D',
				border: '#23985D',
			},
		},
		type: 'opt-in',
		content: {
			message:
				'Diese Webseite benötigt Cookies für die Bereitstellung bestimmter Funktionen und kann optional Cookies zur Auswertung und Verbesserung der Webseite verwenden.',
			href: 'https://software-design.de/agentur/datenschutz?domain=easyverein.com',
			deny: 'Nur funktionale Cookies',
			link: 'Mehr erfahren',
			allow: 'Alle Cookies erlauben',
			policy: 'Cookie Einstellungen',
		},
		onInitialise: function (status) {
			if (this.hasConsented()) {
				// if you remember that the user has given consent, give consent to matomo
				matomoGiveConsent()
			} else {
				// if your remember that the user has not given consent, revert consent to matomo
				matomoRevertConsent()
			}
		},
		onStatusChange: function (status) {
			if (this.hasConsented()) {
				// if user gives consent, give consent to matomo
				matomoGiveConsent()
				const websiteId = defineWebsiteId()
				if (websiteId !== false) {
					matomoInit(websiteId)
					matomoTagManagerInit()
				}
			} else {
				// if user denies consent, revert consent to matomo
				matomoRevertConsent()
			}
		},
	})
}

/**
 * Determine correct tracking
 *
 * @returns {number} website ID of the website in Matomo
 */
function defineWebsiteId() {
	// if page starts with /app/ but not /app/register or /app/login or /app/pricing
	const path = window.location.pathname

	// if any other domain than easyverein.com
	if (window.location.hostname !== 'easyverein.com') {
		return false
	}

	if (
		path.startsWith('/app/') &&
		path !== '/app/' &&
		!path.startsWith('/app/register') &&
		!path.startsWith('/app/pricing')
	) {
		return 4
	} else if (path.startsWith('/public')) {
		return false
	} else {
		return 1
	}
}
/**
 * init matomo without using cookies
 *
 * @param {number} website ID of the website in Matomo
 */
function matomoInit(website) {
	const _paq = (window._paq = window._paq || [])
	_paq.push(['setDocumentTitle', `${document.domain}/${document.title}`])
	_paq.push(['setDomains', ['*.easyverein.com']])
	_paq.push(['requireCookieConsent'])
	_paq.push(['trackPageView'])
	_paq.push(['enableLinkTracking'])

	// check if there is a number within the url with at least two digits in it
	const url = window.location.href
	const regex = /\d{2,}/
	if (regex.test(url)) {
		// replace the number with "ID" as a placeholder
		const anonymizedUrl = url.replace(regex, 'ID')
		_paq.push(['setCustomUrl', anonymizedUrl])
	}

	;(function () {
		const u = '//analytics.tools.sd-server.de/'
		_paq.push(['setTrackerUrl', u + 'matomo.php'])
		_paq.push(['setSiteId', website])
		const d = document
		const g = d.createElement('script')
		const s = d.getElementsByTagName('script')[0]
		g.type = 'text/javascript'
		g.async = true
		g.src = u + 'matomo.js'
		s.parentNode.insertBefore(g, s)
	})()
}

/**
 * init matomo tag manager
 */
function matomoTagManagerInit() {
	const _mtm = (window._mtm = window._mtm || [])
	_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
	;(function () {
		const d = document
		const g = d.createElement('script')
		const s = d.getElementsByTagName('script')[0]
		g.async = true
		g.src = 'https://analytics.tools.sd-server.de/js/container_8cIlu9FR.js'
		s.parentNode.insertBefore(g, s)
	})()
}

/**
 * give consent for matomo
 */
function matomoGiveConsent() {
	const _paq = (window._paq = window._paq || [])
	// remember that the user has given consent and explicitly give consent
	_paq.push(['rememberCookieConsentGiven'])
	_paq.push(['setCookieConsentGiven'])

	const userID = getGlobals().userID
	if (userID) {
		_paq.push(['setUserId', userID])
	}
}

/**
 * revert consent for matomo
 */
function matomoRevertConsent() {
	const _paq = (window._paq = window._paq || [])
	// remember that the user has not given consent and explicitly forget consent
	_paq.push(['rememberCookieConsentGiven'])
	_paq.push(['forgetCookieConsentGiven'])
}
