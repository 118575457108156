import { show } from './helpers/show.js'
import { scrollToTop } from './animation/scroll.js'
import { gettext } from './translation.js'

export class ButtonLoader {
	/**
	 * Toggle's a loading animation and the disable attribute for a button.
	 *
	 * @param {object} btn - button element
	 * @param {string} msg - message, optional
	 * @param {string} msgColor - bootstrap color, optional
	 * @param {string} loaderColor - bootstrap color, optional
	 * @param {string} loaderSize - bootstrap loader size, optional
	 * @param {string} loaderType - bootstrap loader type, optional
	 */
	constructor(
		btn,
		msg = '',
		msgColor = 'text-dark',
		loaderColor = 'text-dark',
		loaderSize = 'spinner-border-sm',
		loaderType = 'spinner-border'
	) {
		if (!btn?.innerHTML || !btn?.tagName?.toLowerCase() === 'button') {
			return 'Invalid argument: btn'
		}

		this.btn = btn
		this.innerHTML = btn.innerHTML
		this.loader = `<div class="${loaderType} ${loaderSize} ${loaderColor}" role="status"></div><span class="${msgColor}">&nbsp;${msg}</span>`
	}

	/**
	 * Disables a button and changes the inner html to an loader.
	 */
	start() {
		this.btn.innerHTML = this.loader
		this._disableBtn()
	}

	/**
	 * Enables a button and changes the inner html back to it's original value.
	 */
	stop() {
		this.btn.innerHTML = this.innerHTML
		this._enableBtn()
	}

	/**
	 * Disables a button.
	 */
	_disableBtn() {
		this.btn.setAttribute('disabled', true)

		this.btn.classList.add('btn-dark')
		this.btn.classList.add('bg-gray')
		this.btn.classList.add('text-black')
	}

	/**
	 * Enables a button.
	 */
	_enableBtn() {
		this.btn.removeAttribute('disabled')

		this.btn.classList.remove('btn-dark')
		this.btn.classList.remove('bg-gray')
		this.btn.classList.remove('text-black')
	}
}

/**
 * The loader for the export or the statistics
 *
 * @param {string} description - The description of the loader
 * @param {boolean} withScrolling - Boolean representing if the page should scroll up to the top (where the loader displays) or not
 */
export function loader(description, withScrolling = false) {
	document.querySelector('#loading h4').innerText = description
	show('#loading')

	if (withScrolling) {
		scrollToTop()
	}
}

/**
 * Set the loading circle spinner to the given element if it is not already set
 *
 * @param {Element} element - A html element to show the spinner circle inside
 * @param {boolean} centered - (optional) Set the spinner in the center of the element
 * @param {boolean} keepContent - (optional) Don't reset the content of the element
 * @param {boolean} spinnerSmall - (optional) size of the spinner
 */
export function setLoadingCircle(
	element,
	centered = true,
	keepContent = false,
	spinnerSmall = true
) {
	setLoadingCircleText(element, undefined, centered, keepContent, spinnerSmall)
}

/**
 * Set the loading circle spinner to the given element if it is not already set
 *
 * @param {Element} element - A html element to show the spinner circle inside
 * @param {string} text - Text to show below the spinner. If falsy don't show the text.
 * @param {boolean} centered - (optional) Set the spinner in the center of the element
 * @param {boolean} keepContent - (optional) Dont reset the content of the element
 * @param {boolean} spinnerSmall - (optional) size of the spinner
 */
export function setLoadingCircleText(
	element,
	text,
	centered = true,
	keepContent = false,
	spinnerSmall = true
) {
	if (!element.innerHTML.includes('spinner-border')) {
		let textSpan = `<span class="visually-hidden">${gettext('Laden')}…</span>`
		if (text) {
			textSpan = `<span>${text}</span>`
		}
		const loader = `
			<span class="loading loading-circle ${
				centered
					? 'd-flex flex-column align-items-center justify-content-center'
					: ''
			}">
			<span class="spinner-border ${
				spinnerSmall ? 'spinner-border-sm' : ''
			}" role="status" aria-hidden="true"></span>
			${textSpan}
			</span>
		`
		element.innerHTML = keepContent ? element.innerHTML + ` ${loader}` : loader
	}
}

/**
 * Removes all loading circles (those set by _setLoadingCircle)
 *
 * @param {string} [containerSelector='body'] - (optional) The selector of the element containing the spinner to remove
 */
export function clearAllLoadingCircles(containerSelector = 'body') {
	document
		.querySelectorAll(`${containerSelector} .loading.loading-circle`)
		.forEach((span) => {
			span.remove()
		})
}
