import { Tooltip } from 'bootstrap'

/**
 * Adds the BS tooltips to all elements matching the selector
 *
 * @param {string} selector - The selector of the elements to add the tooltip
 */
export function addTooltips(selector) {
	// Tooltips are disabled on mobile (they dont make any sence)
	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	)
		return
	document
		.querySelectorAll(selector)
		.forEach((element) => Tooltip.getOrCreateInstance(element))
}
/**
 * Adds the BS tooltips to a given element
 *
 * @param {object} element - The element to add a tooltip to
 */
export function addTooltipsToElement(element) {
	// Tooltips are disabled on mobile (they dont make any sence)
	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	)
		return
	Tooltip.getOrCreateInstance(element)
}

/**
 * Disposed the tooltips of all elements matching the selector
 *
 * @param {string} selector - The selector of the elements to dispose the tooltip
 */
export function disposeTooltips(selector) {
	document
		.querySelectorAll(selector)
		.forEach((element) => disposeTooltipsFromElement(element))
}

/**
 * Disposes a tooltip from the DOM element
 *
 * @param {object} element - The DOM object to dispose a tooltip
 */
export function disposeTooltipsFromElement(element) {
	Tooltip.getOrCreateInstance(element).dispose()
}

/**
 * Hiding the tooltips of all elements matching the selector
 *
 * @param {string} selector - The selector of the elements to hide the tooltip
 */
export function hideTooltips(selector) {
	document
		.querySelectorAll(selector)
		.forEach((element) => hideTooltipsFromElement(element))
}

/**
 * Hiding a tooltip from the DOM element
 *
 * @param {object} element - The DOM object to hide a tooltip
 */
export function hideTooltipsFromElement(element) {
	Tooltip.getOrCreateInstance(element).hide()
}
