import { clearCacheForAdmins } from './clearCacheForAdminsHelper'
import { getCSRF, getGlobals, getContextData } from './django'

/**
 * Maps language codes to locale
 */
const localeMap = {
	en: 'en-US',
	de: 'de-DE',
}

/**
 * Indicates a text for translation
 *
 * @param {string} text - text to translate
 * @returns {string} translated text
 */
export function gettext(text) {
	return window.gettext(text)
}

/**
 * Provides an interface to pluralize words and phrases
 *
 * @param {string} singular - string to translate for a singular object
 * @param {string} plural - string to translate for multiple objects
 * @param {number} objectCount - determines if `singular` (`objectCount === 1`) or `plural` is going to be used
 * @returns {string} translated string depending on objectCount
 */
export function ngettext(singular, plural, objectCount) {
	return window.ngettext(singular, plural, objectCount)
}

/**
 * Supports dynamically populating a format string.
 * The interpolation syntax is borrowed from Python, so the interpolate function supports both positional and named interpolation.
 *
 * Eg:
 * - interpolate('There are %s objects. Remaining: %s', [11, 20]) // => 'There are 11 objects. Remaining: 20'
 * - interpolate('There are %(count)s of a total of %(total)s objects', {count: 10, total: 50}) // => 'There are 10 of a total of 50 objects'
 *
 * @param {string} format - string that may contain either `%s`, whenn `data` is an array, or `%(name)s` when `data` is an object containing `name` as a key
 * @param {Array<string|number>|object} data - data that is filled into `format`
 * @returns {string} format with data filled in
 */
export function interpolate(format, data) {
	if (data === null) {
		console.error('`data` is null, but should be an array or an object')
		return format
	}
	const dataIsObject = !Array.isArray(data) && typeof data === 'object'
	return window.interpolate(format, data, dataIsObject)
}

/**
 * Has access to the configured i18n formatting settings and can retrieve the format string for a given name.
 *
 * @param {string} djangoFormatType - one of the following:
 * `'DATE_FORMAT'`, `'DATE_INPUT_FORMATS'`, `'DATETIME_FORMAT'`, `'DATETIME_INPUT_FORMATS'`,
 * `'DECIMAL_SEPARATOR'`, `'FIRST_DAY_OF_WEEK'`, `'MONTH_DAY_FORMAT'`, `'NUMBER_GROUPING'`,
 * `'SHORT_DATE_FORMAT'`, `'SHORT_DATETIME_FORMAT'`, `'THOUSAND_SEPARATOR'`,
 * `'TIME_FORMAT'`, `'TIME_INPUT_FORMATS'`, `'YEAR_MONTH_FORMAT'`
 *  See [Django Docs](https://docs.djangoproject.com/en/3.2/topics/i18n/translation/#get-format) for details
 * @returns {string} format string of the given format type
 */
export function getFormat(djangoFormatType) {
	return window.get_format(djangoFormatType)
}

/**
 * Translates the Invoice.kind property, similiar to what the backend does with Invoice.getKindTranslation()
 *
 * @param {string} kind - "kind" property of an invoice instance
 * @returns {string} translated plaintext version of `kind`
 */
export function translateInvoiceKind(kind) {
	switch (kind) {
		case 'revenue':
			return gettext('Einnahmen')
		case 'donation':
			return gettext('Spende')
		case 'membership':
			return gettext('Mitgliedsbeitrag')
		case 'expense':
			return gettext('Ausgabe')
		case 'credit':
			return gettext('Gutschrift')
		case 'cancel':
			return gettext('Stornorechnung')

		default:
			return gettext('Unbestimmter Rechnungstyp')
	}
}

/**
 * Translates custom field kind
 *
 * @param {string} kind - Kind of the custom field
 * @returns {string} Translation
 */
export function translateKind(kind) {
	switch (kind) {
		case 'e':
			return gettext('Mitgliederfeld')
		case 'h':
			return gettext('Terminfeld')
		case 'j':
			return gettext('Adressfeld')
		case 'i':
			return gettext('Inventarobjektfeld')
		default:
			return gettext('Ubekannter Typ')
	}
}

/**
 * Retrieves the language code from context
 *
 * @returns {string} language code or 'de', if none is set
 */
export function getLanguageCode() {
	const languageCode = getGlobals().languageCode
	if (typeof languageCode === 'string') return languageCode.toLocaleLowerCase()
	return 'de'
}

/**
 * Retrieves the most fitting locale based on the context data language code
 *
 * @returns {string} locale string
 */
export function getLocale() {
	const languageCode = getLanguageCode()
	return localeMap[languageCode]
}

/**
 * Click callback for the change language button.
 *
 * @param {string} newLang - (optional) The lower languagecode of the selected language
 */
export async function changeLanguage(newLang = null) {
	newLang = newLang || document.querySelector('#language').value
	if (newLang === getLanguageCode()) return

	const userID = getGlobals().userID
	let url = `/api/latest/member/${userID.toString()}`
	if (getContextData('isTaxUser')) {
		url = `/app/bookkeeping/taxuser/`
	}

	const bodyData = { personalSettingsPreferredLanguage: newLang }
	if (userID) {
		await fetch(url, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				'X-CSRFToken': getCSRF(),
			},
			body: JSON.stringify(bodyData),
		})
	}

	fetch('/i18n/setlang/', {
		method: 'POST',
		headers: {
			'X-CSRFToken': getCSRF(),
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: `language=${newLang}`,
	}).then((successData) => {
		if (successData.status === 200) {
			const languageSelect = document.querySelector('#language')
			if (languageSelect) {
				languageSelect.value = newLang // If the setting was changed in the personal settings
			}
			clearCacheForAdmins(true)
		}
	})
}
